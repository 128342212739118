import {
  ArrowDownTrayIcon,
  DocumentCheckIcon,
  EnvelopeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { OtherButtons } from "../../components/buttons/OtherButtons";
import { Typography } from "../../components/typography/Typography";

const LatestSubs = () => {
  //dummy data
  const dummyData = [
    {
      name: "John Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Early",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
  ];
  //Table columns
  const columns = [
    {
      name: <Typography.MediumText text="Student" xStyle="text-defaultText" />,
      cell: (row: any) => (
        <div className="flex justify-between items-center mt-[10px] border-[1px] border-defaultBlue_40 w-full h-[50px] rounded-[20px] px-[15px]">
          <div className="flex gap-[5px] items-center">
            <Image
              className="w-[32px] h-[32px] rounded-full"
              src={row.image}
              alt="..."
              fluid
            />
            <Typography.MediumText text={row.name} xStyle="text-defaultBlue" />
          </div>
          <div className="border border-y-0 border-r-0 border-l-defaultBlue_40 h-8"></div>
          <div>
            <Typography.SmallText
              text={row.status}
              xStyle="text-defaultGreen bg-defaultGreen bg-opacity-[10%] px-2 rounded-[20px]"
            />
          </div>
          <div className="border border-y-0 border-r-0 border-l-defaultBlue_40 h-8"></div>
          <div className="flex gap-[20px] items-center text-defaultBlue">
            <ArrowDownTrayIcon className="w-4 h-4 cursor-pointer" />
            <DocumentCheckIcon className="w-4 h-4 cursor-pointer" />
            <EnvelopeIcon className="w-4 h-4 cursor-pointer" />
            <LockClosedIcon className="w-4 h-4 cursor-pointer" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="mt-[30px] bg-white rounded-[20px] shadow-sm py-[20px] px-[30px] text-defaultText">
      <div className="flex justify-between items-center">
        <Typography.MediumText
          text="Latest Submissions"
          xStyle="font-semibold"
        />
        <OtherButtons.SeeAll
          btnName="See all"
          onClick={() => alert("Seeing all")}
        />
      </div>

      <div>
        <DataTable
          responsive
          progressComponent="Loading..."
          // progressPending={isFetching}
          data={dummyData}
          columns={columns}
          selectableRows
          onSelectedRowsChange={(dd) => console.log(dd)}
          paginationServer
          noDataComponent={
            <div>
              <p>No data to display</p>
            </div>
          }
        />
      </div>
    </div>
  );
};
export default LatestSubs;
