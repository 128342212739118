import { Typography } from "../../../../components/typography/Typography";
import { Image, Row } from "react-bootstrap";
import badgeImg from "../../../../images/badgeImg.png";
import { Link } from "react-router-dom";

const Badges = () => {
  return (
    <div>
      <Row>
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
        <SingleBadge badgeTitle="Badge Name" dateIssued="Jul. 32, 2023" />
      </Row>
    </div>
  );
};

interface badgeProps {
  navTo?: any;
  onClick?: () => void;
  badgeTitle: string;
  dateIssued: string;
}

//data mapper
const SingleBadge = ({
  navTo,
  onClick,
  badgeTitle,
  dateIssued,
}: badgeProps) => {
  return (
    <Link
      to={navTo}
      onClick={onClick}
      className="h-[160px] w-[230px] rounded-[20px] bg-defaultWhite shadow-sm ml-[17px] mb-[24px] px-[36px] py-[15px] col-md-4"
    >
      <div className="flex flex-col justify-center items-center">
        <Image src={badgeImg} alt="..." fluid />
        <Typography.MediumText
          text={badgeTitle}
          xStyle="font-semibold text-defaultText mt-[10px]"
        />
        <Typography.SmallText text={`Issued: ${dateIssued}`} />
      </div>
    </Link>
  );
};

export default Badges;
