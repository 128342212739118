import { Col, Row } from "react-bootstrap";
import { Typography } from "../../../../components/typography/Typography";

const Grades = () => {
  return (
    <>
      <div className="bg-defaultWhite shadow-sm rounded-[20px] pt-[15px] pb-[30px] px-[30px] text-defaultText">
        <Typography.RegularText text="Grade" xStyle="font-semibold" />

        <div className="mt-[18px]">
          <Row className="text-defaultText">
            <Col md={3} className="text-center">
              <Typography.MediumText text="Task" />
            </Col>
            <Col md={3} className="text-center">
              <Typography.MediumText text="Status" />
            </Col>
            <Col md={3} className="text-center">
              <Typography.MediumText text="Weight" />
            </Col>
            <Col md={3} className="text-center">
              <Typography.MediumText text="Grade" />
            </Col>
          </Row>

          {/* sample grade */}
          <SampleGrade />
          <SampleGrade />
          <SampleGrade />
          <SampleGrade />

          {/* results */}
          <Row className="bg-defaultBlue_5 rounded-[20px] p-2 pt-[14px] h-[52px] mt-2">
            <Col md={3} className="flex items-center">
              <Typography.MediumText
                text="Total Grade"
                xStyle="text-defaultBlue font-semibold"
              />
            </Col>

            <Col md={3} className="text-center"></Col>

            <Col
              md={3}
              className="text-center flex justify-center items-center"
            >
              <Typography.MediumText
                text="79%"
                xStyle="text-defaultBlue font-semibold"
              />
            </Col>

            <Col
              md={3}
              className="text-center flex justify-center items-center"
            >
              <Typography.MediumText
                text="100%"
                xStyle="text-defaultBlue font-semibold"
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

//data mapper
const SampleGrade = () => {
  return (
    <>
      <Row className="border border-defaultBlue_40 rounded-[20px] p-2 mt-2">
        {/* left */}
        <Col md={3}>
          <div className="flex gap-[20px] items-center w-[231px]">
            <div className="w-[38px] h-[40px] rounded-[10px] bg-defaultBlue_20 flex flex-col justify-center items-center">
              <div className="bg-defaultBlue text-defaultWhite w-full h-[16px] rounded-full flex justify-center items-center">
                <Typography.SmallText text="Feb" />
              </div>
              <Typography.MediumText text="24" xStyle="text-defaultBlue" />
            </div>

            <div>
              <Typography.MediumText
                text="Lecture 1"
                xStyle="text-defaultBlue font-semibold"
              />

              <div className="flex gap-[8px] item-center">
                <p className="bg-defaultYellow text-defaultWhite text-center h-[17px] font-medium text-[12px] rounded-[20px] w-auto px-[6px]">
                  Homework
                </p>

                <Typography.SmallText
                  text="8:00GMT"
                  xStyle="font-medium text-defaultGray"
                />
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} className="text-center flex items-center justify-center">
          <span className="w-[78spanx] rounded-[30px] text-defaultGreen border-[1.5px] text-[12px] border-defaultGreen px-2">
            Completed
          </span>
        </Col>

        <Col md={3} className="text-center flex justify-center items-center">
          <Typography.RegularText
            text="24"
            xStyle="text-defaultText font-semibold"
          />
        </Col>

        <Col md={3} className="text-center flex justify-center items-center">
          <Typography.RegularText
            text="24"
            xStyle="text-defaultText font-semibold"
          />
        </Col>
      </Row>
    </>
  );
};

export default Grades;
