import { Image } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";

const Instructors = () => {
  return (
    <div>
      <Typography.MediumText
        text="Instructors"
        xStyle="text-defaultText font-semibold"
      />

      <EachParent
        image="https://www.timeforkids.com/wp-content/uploads/2022/02/KOTY4.jpg"
        name="Mawuli Togbor"
        phone="+233000111223"
        email="lorem@mingo.com"
      />
      <EachParent
        image="https://www.timeforkids.com/wp-content/uploads/2022/02/KOTY4.jpg"
        name="Mawuli Togbor"
        phone="+233000111223"
        email="lorem@mingo.com"
      />
    </div>
  );
};

//child component
const EachParent = ({ name, phone, email, image }: any) => {
  return (
    <div className="mt-[13px] flex gap-[18px] items-center">
      <Image
        className="w-[46px] h-[46px] object-cover rounded-full"
        src={image}
        alt="..."
        fluid
      />

      <div>
        <Typography.SmallText text={name} xStyle="text-defaultText" />
        <Typography.SmallText text={phone} xStyle="text-defaultText" />
        <Typography.SmallText text={email} xStyle="text-defaultBlue" />
      </div>
    </div>
  );
};

export default Instructors;
