import { Form } from "react-bootstrap";
import {
  PencilSquareIcon,
  TrashIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { Typography } from "../../../../components/typography/Typography";

const Notes = () => {
  return (
    <>
      <div className="bg-defaultWhite shadow-sm rounded-[20px] pt-[15px] pb-[30px] pl-[30px] pr-[200px] text-defaultText">
        <Typography.RegularText text="Notes" xStyle="font-semibold" />

        <div className="flex justify-between items-center mt-[18px]">
          <Form.Select className="w-[190px] shadow-none border-[1px] border-defaultBlue_40 rounded-full">
            <option>All Lectures</option>
            <option>Deadline</option>
            <option>Tasks</option>
            <option>Events</option>
          </Form.Select>
          <Form.Select className="w-[190px] shadow-none border-[1px] border-defaultBlue_40 rounded-full">
            <option>Personal</option>
            <option>Deadline</option>
            <option>Tasks</option>
            <option>Events</option>
          </Form.Select>
        </div>

        <div className="mt-[18px]">
          <SampleNotes />
          <SampleNotes />
          <SampleNotes />
        </div>
      </div>
    </>
  );
};

//data mapper
const SampleNotes = () => {
  return (
    <>
      <div className="mt-[35px]">
        <div className="flex gap-[20px] justify-center">
          <div className="text-defaultText flex gap-[5px]">
            <VideoCameraIcon className="w-5 h-5" />
            <small className="w-[43px] h-5 rounded-full border-[1.5px] border-defaultText flex items-center justify-center">
              1:45
            </small>
          </div>
          <div>
            <Typography.MediumText
              text="Introduction to Electronics"
              xStyle="font-semibold text-defaultBlue"
            />
            <Typography.MediumText
              text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem"
              xStyle="text-defaultText mt-[5px]"
            />

            <div className="mt-[10px] flex gap-[40px]">
              <button className="text-defaultBlue flex gap-[14px] justify-center items-center font-semibold">
                <PencilSquareIcon className="w-4 h-4" />
                <Typography.MediumText text="Edit" />
              </button>
              <button className="text-defaultBlue flex gap-[14px] justify-center items-center font-semibold">
                <TrashIcon className="w-4 h-4" />
                <Typography.MediumText text="Delete" />
              </button>
            </div>
          </div>
        </div>
        <hr className="my-[20px]" />
      </div>
    </>
  );
};

export default Notes;
