import { Typography } from "../../../components/typography/Typography";
import { MingoInput } from "../../../components/inputs/MingoInput";
import { MagnifyingGlassIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Col, Image, Row } from "react-bootstrap";

const StudentReview = () => {
  return (
    <Row>
      <Col md={9}>
        <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[20px]">
          <Typography.MediumText text="Student Review" />

          <div className="flex justify-between items-center gap-5">
            <div className="w-[385px]">
              <MingoInput.RoundedWithIcon
                icon={
                  <MagnifyingGlassIcon className="w-5 h-5 text-defaultBlue_40 absolute bottom-[10px] ml-2" />
                }
              />
            </div>
            <div className="w-[264px]">
              <MingoInput.OptionsSelect
                options={<option value="1">Term 1</option>}
              />
            </div>
          </div>

          <div className="mt-[30px]">
            <Row>
              <Col md={4}>
                <Typography.MediumText text="Student" />
              </Col>
              <Col md={8}>
                <Typography.MediumText text="Review" />
              </Col>
            </Row>

            <Row className="mt-[8px] h-[160px] border-[1px] border-defaultBlue_40 rounded-[10px] py-[15px]">
              <Col md={4}>
                <div className="flex gap-[10px] items-center">
                  <Image
                    className="w-[32px] h-[32px] rounded-full object-cover"
                    src="https://t3.ftcdn.net/jpg/05/38/42/42/360_F_538424220_7rUirYo7uXLgkS7PFOFa4rb38rZihCXk.jpg"
                    alt="..."
                    fluid
                  />
                  <Typography.MediumText
                    text="Mawuli Togbor"
                    xStyle="text-defaultBlue"
                  />
                </div>
              </Col>
              <Col md={8}>
                <MingoInput.InputWithTools />
              </Col>
            </Row>

            <Row className="mt-[8px] h-[160px] border-[1px] border-defaultBlue_40 rounded-[10px] py-[15px]">
              <Col md={4}>
                <div className="flex gap-[10px] items-center">
                  <Image
                    className="w-[32px] h-[32px] rounded-full object-cover"
                    src="https://t3.ftcdn.net/jpg/05/38/42/42/360_F_538424220_7rUirYo7uXLgkS7PFOFa4rb38rZihCXk.jpg"
                    alt="..."
                    fluid
                  />
                  <Typography.MediumText
                    text="Mawuli Togbor"
                    xStyle="text-defaultBlue"
                  />
                </div>
              </Col>
              <Col md={8}>
                <MingoInput.InputWithTools />
              </Col>
            </Row>

            <Row className="mt-[8px] h-[160px] border-[1px] border-defaultBlue_40 rounded-[10px] py-[15px]">
              <Col md={4}>
                <div className="flex gap-[10px] items-center">
                  <Image
                    className="w-[32px] h-[32px] rounded-full object-cover"
                    src="https://t3.ftcdn.net/jpg/05/38/42/42/360_F_538424220_7rUirYo7uXLgkS7PFOFa4rb38rZihCXk.jpg"
                    alt="..."
                    fluid
                  />
                  <Typography.MediumText
                    text="Mawuli Togbor"
                    xStyle="text-defaultBlue"
                  />
                </div>
              </Col>
              <Col md={8}>
                <MingoInput.InputWithTools />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={3}>
        <button className="rounded-[5px] text-white bg-defaultBlue h-[40px] w-auto px-3 flex justify-center items-center gap-[10px]">
          <UserPlusIcon className="w-5 h-5" />
          Add New Template
        </button>

        <MingoInput.OptionsSelect options={<option value="1">Term 1</option>} />

        <MingoInput.OptionsSelect
          options={<option value="1">Grade 1</option>}
        />
      </Col>
    </Row>
  );
};

export default StudentReview;
