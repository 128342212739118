import { Typography } from "../typography/Typography";
import welcomeImg from "../../images/welcomeImg.png";
import { Image } from "react-bootstrap";

interface currProps {
  courseTitle?: string;
  details1?: string;
  details2: string;
  image?: any;
  percent?: any;
}

const CurrentCourses = ({
  courseTitle,
  details1,
  details2,
  image,
  percent,
}: currProps) => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] py-[10px] pr-[20px] pl-[10px] h-[106px] bg-defaultWhite">
      <div className="flex gap-[12px]">
        {image}

        <div className="flex gap-[4px] justify-between w-full">
          <div className="h-[79px] w-[137px]">
            <Typography.MediumText
              text={courseTitle}
              xStyle="font-semibold text-defaultBlue"
            />
            <Typography.MediumText text={details1} xStyle="mt-[10px]" />
            <Typography.MediumText text={details2} />
          </div>

          <div className="relative inline-flex items-center justify-center overflow-hidden rounded-full">
            <svg className="w-[80px] h-[80px]">
              <circle
                className="text-defaultBlue_20"
                stroke-width="3"
                stroke="currentColor"
                startOffset={0}
                fill="transparent"
                r="30"
                cx="40"
                cy="40"
              />
              <circle
                className="text-defaultYellow"
                stroke-width="3"
                startOffset={0}
                strokeDasharray={188.5}
                strokeDashoffset={188.5 - (percent / 100) * 188.5}
                stroke-linecap="round"
                stroke="currentColor"
                fill="transparent"
                r="30"
                cx="40"
                cy="40"
              />
            </svg>
            <span className="absolute z-50 text-[21px] text-defaultText font-semibold">
              {percent}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface sugProps {
  courseTitle?: string;
  details1?: string;
  image?: any;
}

const SuggestedCourses = ({ image, details1, courseTitle }: sugProps) => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] py-[10px] pr-[20px] pl-[10px] h-[106px] bg-defaultWhite">
      <div className="flex gap-[21px]">
        {image}

        <div className="h-[79px]">
          <Typography.MediumText
            text={courseTitle}
            xStyle="font-semibold text-defaultBlue"
          />
          <Typography.MediumText
            text={details1}
            xStyle="mt-[10px] line-clamp-2"
          />
        </div>
      </div>
    </div>
  );
};

const WelcomeCard = () => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] pl-[20px] pt-[20px] h-[117px] bg-defaultWhite">
      <div className="flex justify-between">
        <div>
          <Typography.HeadingFour
            text="Welcome 👋 Jonathan"
            xStyle="text-defaultBlue"
          />
          <Typography.MediumText
            text="Mawuli reached 80% of his goals this week!"
            xStyle="mt-[18px]"
          />
        </div>
        <div className="relative bottom-14 right-3">
          <Image
            src={welcomeImg}
            className="hover:translate-y-1"
            alt="..."
            fluid
          />
        </div>
      </div>
    </div>
  );
};

const ProgressCard = ({
  title,
  value,
  icon,
  progress,
  progressIcon,
  progressText,
}: any) => {
  return (
    <div className="bg-white shadow-sm rounded-[20px] py-[12px] px-[19px] mt-[10px] min-h-[117px] mx-[0px] text-defaultText">
      <Typography.MediumText text={title} xStyle="font-normal" />

      <div className="flex justify-between items-center mt-[5px]">
        <Typography.HeadingThree text={value} />
        <Image className="" src={icon} alt="..." fluid />
      </div>

      <div className="mt-[10px] flex items-center gap-1">
        <Typography.SmallText
          text={progress}
          xStyle="text-secondary-green font-semibold"
        />
        {progressIcon}
        <Typography.SmallText text={progressText} />
      </div>
    </div>
  );
};

export const AllCards = {
  CurrentCourses,
  SuggestedCourses,
  WelcomeCard,
  ProgressCard,
};
