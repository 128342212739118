import { ChevronDownIcon } from "@heroicons/react/24/outline";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { IoPower } from "react-icons/io5";
import { LuUserCircle2 } from "react-icons/lu";
import { ACCOUNTS_URL } from "../../helpers/constants";
import { getCookie, PerformLogout } from "../../helpers/utils";
import { Navigate, useNavigate } from "react-router-dom";

const ProfilePic = () => {
  const navigate = useNavigate();

  const user_data = JSON.parse(getCookie("user_data"));

  //handleOpenAccount
  const handleOpenAccount = () => {
    // window.open(`${ACCOUNTS_URL}/account`)
    navigate("/account");
  };

  //items
  const items: MenuProps["items"] = [
    {
      label: (
        <button
          className="flex gap-2 items-center w-full"
          onClick={handleOpenAccount}
        >
          <LuUserCircle2 className="h-4 w-4" />
          <p className="font-medium">My Account</p>
        </button>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <button
          className="flex gap-2 items-center w-full text-red-500"
          onClick={PerformLogout}
        >
          <IoPower className="h-4 w-4" />
          <p className="font-medium">Logout</p>
        </button>
      ),
      key: "2",
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Space className="flex gap-1 items-center">
        <img
          src={
            user_data?.image ||
            "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"
          }
          alt=""
          className="w-[32px] h-[32px] rounded-full outline outline-1 outline-white object-cover"
          style={{ cursor: "pointer" }}
        />
        <ChevronDownIcon className="h-4 w-4 mt-2" />
      </Space>
    </Dropdown>
  );
};

export default ProfilePic;
