import { Form } from "react-bootstrap";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Cornered = ({
  label,
  type,
  placeholder,
  required,
  value,
  onChange,
}: any) => {
  return (
    <>
      <label htmlFor={label} className="text-sm">
        {label}
      </label>
      <input
        className={`rounded-[5px] border-[1px] px-[10px] border-defaultBlue_40 h-[40px] w-full focus:border-[2px] focus:outline-none`}
        type={type}
        id={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="off"
        required={required}
      />
    </>
  );
};

const Rounded = ({
  label,
  type,
  placeholder,
  required,
  value,
  onChange,
}: any) => {
  return (
    <>
      <label htmlFor={label} className="text-sm">
        {label}
      </label>
      <input
        className={`rounded-[20px] border-[1px] px-[10px] border-defaultBlue_40 h-[40px] w-full focus:border-[2px] focus:outline-none`}
        type={type}
        id={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="off"
        required={required}
      />
    </>
  );
};

const RoundedWithIcon = ({
  label,
  type,
  placeholder,
  icon,
  required,
  value,
  onChange,
}: any) => {
  return (
    <>
      <label htmlFor={label} className="text-sm">
        {label}
      </label>
      <div className="relative">
        <input
          className={`rounded-[20px] pl-[28px] border-[1px] px-[10px] border-defaultBlue_40 h-[40px] w-full focus:border-[2px] focus:outline-none`}
          type={type}
          id={label}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="off"
          required={required}
        />
        {icon}
      </div>
    </>
  );
};

const TextArea = ({ label, placeholder, required, value, onChange }: any) => {
  return (
    <>
      <label htmlFor={label} className="text-sm">
        {label}
      </label>
      <textarea
        className={`rounded-[5px] border-[1px] px-[10px] py-[5px] border-defaultBlue_40 h-[80px] w-full focus:border-[2px] focus:outline-none`}
        id={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="off"
        required={required}
      />
    </>
  );
};

const OptionsSelect = ({ label, value, onChange, options }: any) => {
  return (
    <>
      <label htmlFor="formSelect" className="text-sm">
        {label}
      </label>
      <Form.Select
        className={`rounded-[5px] border-[1px] px-[10px] shadow-none py-[5px] border-defaultBlue_40 h-[40px] w-full focus:border-[2px] focus:outline-none`}
        id="formSelect"
        value={value}
        onChange={onChange}
      >
        <option value="Select...">Select...</option>
        {options}
      </Form.Select>
    </>
  );
};

const Switch = ({ checked, value, onChange }: any) => {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input shadow-none"
        type="checkbox"
        value={value}
        onChange={onChange}
        role="switch"
        id="flexSwitchCheckChecked"
        checked={checked}
      />
    </div>
  );
};

const MultiSelect = ({ options, isMulti, value, onChange, isLoading }: any) => {
  return (
    <Select
      options={options}
      isMulti={isMulti}
      value={value}
      onChange={onChange}
      isLoading={isLoading}
    />
  );
};

const InputWithTools = ({ value, onChange, height }: any) => {
  return (
    <ReactQuill
      className={height}
      theme="snow"
      value={value}
      onChange={onChange}
    />
  );
};

const CheckBox = ({ label, checked, value, onChange }: any) => {
  return (
    <div className="flex items-center gap-[5px]">
      <input
        id={label}
        type="checkbox"
        className="w-[17px] h-[18px]"
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

const RadioCheck = ({ label, checked, value, onChange }: any) => {
  return (
    <Form.Check
      label={label}
      name={label}
      type="radio"
      value={value}
      onChange={onChange}
      checked={checked}
    />
  );
};

const ImageSelect = ({ label, value, onChange }: any) => {
  return (
    <>
      <label htmlFor={label} className="text-sm">
        {label}
      </label>
      <input
        className={`px-[10px]h-[40px] w-full focus:border-[2px] text-defaultBlue`}
        type="file"
        id={label}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export const MingoInput = {
  Cornered,
  Rounded,
  RoundedWithIcon,
  TextArea,
  OptionsSelect,
  Switch,
  MultiSelect,
  InputWithTools,
  CheckBox,
  ImageSelect,
  RadioCheck,
};
