import { Col, Form, Row } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  MagnifyingGlassIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import MyClassTable from "./MyClassTable";
import { useNavigate } from "react-router-dom";

const MyClass = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar myClassActive="active" myClassIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="My Class" />

          <Row className="mt-[10px]">
            {/* left side section */}
            <Col md={9} className="mt-[5px]">
              <div className="rounded-[20px] flex justify-between items-center mt-[15px]">
                <div>
                  <Form>
                    <Form.Group className="relative">
                      <Form.Control
                        className="h-[40px] w-[339px] rounded-[25px] shadow-none border-defaultBlue_40 focus:border-defaultBlue_40 pr-8"
                        placeholder="Search"
                      />
                      <MagnifyingGlassIcon
                        className="w-5 h-5 text-defaultBlue_40 absolute right-3 bottom-2.5"
                        type="submit"
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    className="shadow-none border-defaultBlue_40 focus:border-defaultBlue_40 h-[40px] w-[191px] rounded-[20px]"
                  >
                    <option value="paid">Newest</option>
                    <option value="unpaid">Oldest</option>
                  </Form.Select>
                </div>
              </div>
              {/* courses table */}
              <MyClassTable />
            </Col>

            {/* right side section */}
            <Col md={3} className="mt-[5px]">
              <button
                className="w-[170px] h-[40px] rounded-[5px] shadow-sm bg-defaultBlue text-white flex justify-center items-center gap-2"
                onClick={() => navigate("/addClass")}
              >
                <UserPlusIcon className="w-4 h-4" />
                Add New Class
              </button>
              <button className="mt-[20px] w-[126px] h-[40px] shadow-sm rounded-[5px] border-[1px] border-defaultBlue_40 bg-white text-defaultBlue flex justify-center items-center gap-2">
                <ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4" />
                Message
              </button>
              <button className="mt-[20px] w-[192px] h-[40px] shadow-sm rounded-[5px] border-[1px] border-defaultBlue_40 bg-white text-defaultBlue flex justify-center items-center gap-2">
                <UserPlusIcon className="w-4 h-4" />
                Bulk add members
              </button>
              <button className="mt-[20px] w-[174px] h-[40px] shadow-sm rounded-[5px] border-[1px] border-defaultBlue_40 bg-white text-defaultBlue flex justify-center items-center gap-2">
                <UserPlusIcon className="w-4 h-4" />
                Add attendance
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MyClass;
