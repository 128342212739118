import { Col, Row } from "react-bootstrap";
import { AllCards } from "../../components/cards/AllCards";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Typography } from "../../components/typography/Typography";
import SmallCalendar from "./SmallCalendar";
import TodaysActivities from "./TodaysActivities";
import UpcomingEvents from "./UpcomingEvents";
import { ArrowUpIcon } from "@heroicons/react/24/outline";
import studentIcon from "../../images/studentIcon.png";
import taskIcon from "../../images/taskIco.png";
import subIcon from "../../images/subIcon.png";
import pendingIcon from "../../images/pendingIcon.png";
import LatestSubs from "./LatestSubs";

const Dashboard = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar dashboardActive="active" dashIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Dashboard" />

          <Row className="mt-[10px]">
            {/* left column items */}
            <Col className="mt-[11px]" md={9}>
              <AllCards.WelcomeCard />

              {/* progress cards */}
              <Row className="mt-[20px]">
                <Col md={3}>
                  <AllCards.ProgressCard
                    title={"Student"}
                    value={"84"}
                    icon={studentIcon}
                    progress={"1.2%"}
                    progressIcon={
                      <ArrowUpIcon className="w-3 h-3 text-secondary-green font-semibold" />
                    }
                    progressText={"than last week"}
                  />
                </Col>
                <Col md={3}>
                  <AllCards.ProgressCard
                    title={"Tasks"}
                    value={"11"}
                    icon={taskIcon}
                    progress={"1.2%"}
                    progressIcon={
                      <ArrowUpIcon className="w-3 h-3 text-secondary-green font-semibold" />
                    }
                    progressText={"than last week"}
                  />
                </Col>

                <Col md={3}>
                  <AllCards.ProgressCard
                    title={"Submissions"}
                    value={"8"}
                    icon={subIcon}
                    progress={"1.2%"}
                    progressIcon={
                      <ArrowUpIcon className="w-3 h-3 text-secondary-green font-semibold" />
                    }
                    progressText={"than last week"}
                  />
                </Col>

                <Col md={3}>
                  <AllCards.ProgressCard
                    title={"Pending"}
                    value={"2"}
                    icon={pendingIcon}
                    progress={"1.2%"}
                    progressIcon={
                      <ArrowUpIcon className="w-3 h-3 text-secondary-green font-semibold" />
                    }
                    progressText={"than last week"}
                  />
                </Col>
              </Row>

              {/* latest submissions */}
              <div className="hidden md:block">
                <LatestSubs />
              </div>
            </Col>

            {/* right column items */}
            <Col className="mt-[11px]" md={3}>
              {/* calendar section */}
              <SmallCalendar />

              <hr />

              {/* today's activities */}
              <div className="mt-[20px] h-[220px] mb-[10px]">
                <Typography.MediumText
                  text={`Today's Activities`}
                  xStyle="font-semibold mb-[8px]"
                />

                <TodaysActivities />
              </div>

              <hr />

              {/* upcoming events */}
              <div className="mt-[20px] h-[170px] mb-[10px]">
                <Typography.MediumText
                  text="Upcoming Events"
                  xStyle="font-semibold mb-[8px]"
                />

                <UpcomingEvents />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
