// import Navbar from "../../components/navbar/Navbar";
// import ProSideBar from "../../components/sidebar/pro-sidebar";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import interactionPlugin, {
//   Draggable,
//   DropArg,
// } from "@fullcalendar/interaction";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import { Fragment, useEffect, useState } from "react";
// import { Dialog, Transition } from "@headlessui/react";
// import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
// import { EventSourceInput } from "@fullcalendar/core/index.js";
// import AddEvent from "./Modals/AddEvent";
// import BaseService from "../../helpers/baseServices";
// import moment from "moment";

// const Calender = () => {
//   const [isLoadingDetails, setLoadingDetails] = useState<boolean>(false);
//   const [allEvents, setAllEvents] = useState<any>([]);
//   const [showModal, setShowModal] = useState(false);
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [idToDelete, setIdToDelete] = useState<number | null>(null);
//   const [newEvent, setNewEvent] = useState<any>({
//     mytitle: "",
//     start: "",
//     startDate: "",
//     endDatee: "",
//     schoolName: "",
//     academicTermData: "",
//     ageGroup: "",
//     allDay: false,
//     id: 0,
//   });

//   function handleDateClick(arg: { date: Date; allDay: boolean }) {
//     console.log("arg : ", arg);
//     setNewEvent({
//       ...newEvent,
//       start: arg.date,
//       allDay: arg.allDay,
//       id: new Date().getTime(),
//     });
//     setShowModal(true);
//   }

//   function handleDeleteModal(data: { event: { id: string } }) {
//     setShowDeleteModal(true);
//     setIdToDelete(Number(data.event.id));
//   }

//   function handleCloseModal() {
//     setShowModal(false);
//     setNewEvent({
//       mytitle: "",
//       start: "",
//       allDay: false,
//       location: "", // Example additional value
//       description: "", // Example additional value
//       academicTermData: "",
//       startDate: "",
//       endDatee: "",
//       schoolName: "",
//       ageGroup: "",
//       id: 0,
//     });
//     setShowDeleteModal(false);
//     setIdToDelete(null);
//   }

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
//     setNewEvent({
//       ...newEvent,
//       mytitle: e.target.value,
//     });
//   };

//   const handleLoChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
//     setNewEvent({
//       ...newEvent,
//       location: e.target.value,
//     });
//   };
//   const handleDesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
//     setNewEvent({
//       ...newEvent,
//       description: e.target.value,
//     });
//   };
//   const SubmitDocument = async (data: any) => {
//     setLoadingDetails(true);

//     try {
//       //   const response = await BaseService.post_api(
//       //     `/robocentre/roboinstructor/setup/calender`,
//       //     data
//       //   );
//       //   const { payload } = await response.data;
//       //   console.log("payload : ", payload);
//       //   setAllEvents([
//       //     ...allEvents,
//       //     {
//       //       ...newEvent,
//       //       startDate: payload?.meetings[0]?.start,
//       //       endDatee: payload?.meetings[0]?.end,
//       //       schoolName: payload?.schoolData?.schoolInformation?.schoolName,
//       //       ageGroup: `${payload?.geadeLevelData?.ageGroup[0]} - ${
//       //         payload?.geadeLevelData?.ageGroup[
//       //           payload?.geadeLevelData?.ageGroup.length - 1
//       //         ]
//       //       }`,
//       //       academicTermData: payload?.academicTermData?.name,
//       //     },
//       //   ]);

//       setAllEvents([
//         ...allEvents,
//         {
//           ...newEvent,
//           startDate: "09:58.433Z",
//           endDatee: "16:09:58.433Z",
//           schoolName: "IB International School",
//         },
//       ]);
//     } catch (error: any) {
//       console.log("Error fetching getInstrucDetails payload: ", error.message);
//     } finally {
//       setLoadingDetails(false);
//     }
//   };
//   function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
//     e.preventDefault();
//     setAllEvents([...allEvents, newEvent]);
//     setShowModal(false);
//     setNewEvent({
//       mytitle: "",
//       start: "",
//       location: "", // Example additional value
//       description: "", // Example additional value

//       allDay: false,
//       id: 0,
//     });
//   }

//   return (
//     <div className="flex flex-row overflow-hidden h-[100vh] w-full">
//       {/* Sidebar */}
//       <ProSideBar dashboardActive="active" dashIcon="dashIconActive" />

//       {/* Content */}
//       <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
//         <Navbar />

//         {/* main content */}
//         <h1 className="px-[16px] text-[28px] font-bold text-defaultBlue ">
//           Calendar
//         </h1>
//         <main className="mt-6 ">
//           <div className="">
//             <div className="p-[16px]">
//               <FullCalendar
//                 plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
//                 headerToolbar={{
//                   left: "prev,next today",
//                   center: "title",
//                   right: "dayGridMonth,timeGridWeek",
//                 }}
//                 events={allEvents.map(
//                   (event: {
//                     mytitle: any;
//                     id: { toString: () => any };
//                     startDate: any;
//                     endDatee: any;
//                     academicTermData: any;
//                     schoolName: any;
//                     ageGroup: any;
//                   }) => ({
//                     ...event,
//                     title: event.mytitle,
//                     id: event.id.toString(),
//                     extendedProps: {
//                       academicTermData: event.academicTermData,
//                       startDate: event.startDate,
//                       endDatee: event.endDatee,
//                       schoolName: event.schoolName,
//                       ageGroup: event.ageGroup,
//                     },
//                   })
//                 )}
//                 eventContent={(eventInfo) => (
//                   <div>
//                     <b>{eventInfo.event.title}</b>
//                     <p>
//                       {moment(eventInfo.event.extendedProps.startDate).format(
//                         "Do MMM YYYY - hh:mm A"
//                       )}
//                     </p>
//                     <p>
//                       {moment(eventInfo.event.extendedProps.endDatee).format(
//                         "Do MMM YYYY - hh:mm A"
//                       )}
//                     </p>
//                     <p>{eventInfo.event.extendedProps.schoolName}</p>
//                     <p>{eventInfo.event.extendedProps.academicTermData}</p>
//                     <p>{eventInfo.event.extendedProps.ageGroup}</p>
//                   </div>
//                 )}
//                 nowIndicator={true}
//                 editable={true}
//                 droppable={true}
//                 selectable={true}
//                 selectMirror={true}
//                 dateClick={handleDateClick}
//                 eventClick={(data) => handleDeleteModal(data)}
//               />
//             </div>
//           </div>

//           {showModal && (
//             <AddEvent
//               open={showModal}
//               setOpenSMS={setShowModal}
//               handleCloseModal={handleCloseModal}
//               SubmitDocument={SubmitDocument}
//               isLoadingDetails={isLoadingDetails}
//             />
//           )}
//           {/* <Transition.Root show={showModal} as={Fragment}>
//             <Dialog as="div" className="relative z-10" onClose={setShowModal}>
//               <Transition.Child
//                 as={Fragment}
//                 enter="ease-out duration-300"
//                 enterFrom="opacity-0"
//                 enterTo="opacity-100"
//                 leave="ease-in duration-200"
//                 leaveFrom="opacity-100"
//                 leaveTo="opacity-0"
//               >
//                 <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
//               </Transition.Child>

//               <div className="fixed inset-0 z-10 overflow-y-auto">
//                 <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
//                   <Transition.Child
//                     as={Fragment}
//                     enter="ease-out duration-300"
//                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//                     enterTo="opacity-100 translate-y-0 sm:scale-100"
//                     leave="ease-in duration-200"
//                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//                   >
//                     <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
//                       <div>
//                         <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
//                           <CheckIcon
//                             className="h-6 w-6 text-green-600"
//                             aria-hidden="true"
//                           />
//                         </div>
//                         <div className="mt-3 text-center sm:mt-5">
//                           <Dialog.Title
//                             as="h3"
//                             className="text-base font-semibold leading-6 text-gray-900"
//                           >
//                             Add Event
//                           </Dialog.Title>
//                           <form action="submit" onSubmit={handleSubmit}>
//                             <div className="mt-2">
//                               <input
//                                 type="text"
//                                 name="title"
//                                 className="block w-full rounded-md border-0 py-1.5 text-gray-900
//                             shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
//                             focus:ring-2
//                             focus:ring-inset focus:ring-violet-600
//                             sm:text-sm sm:leading-6"
//                                 value={newEvent.mytitle}
//                                 onChange={(e) => handleChange(e)}
//                                 placeholder="Title"
//                               />
//                               <input
//                                 type="text"
//                                 name="location"
//                                 className="block w-full rounded-md border-0 py-1.5 text-gray-900
//                             shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
//                             focus:ring-2
//                             focus:ring-inset focus:ring-violet-600
//                             sm:text-sm sm:leading-6"
//                                 value={newEvent.location}
//                                 onChange={(e) => handleLoChange(e)}
//                                 placeholder="location"
//                               />
//                               <input
//                                 type="text"
//                                 name="description"
//                                 className="block w-full rounded-md border-0 py-1.5 text-gray-900
//                             shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
//                             focus:ring-2
//                             focus:ring-inset focus:ring-violet-600
//                             sm:text-sm sm:leading-6"
//                                 value={newEvent.description}
//                                 onChange={(e) => handleDesChange(e)}
//                                 placeholder="Title"
//                               />
//                             </div>
//                             <div className="mt-5  sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
//                               <button
//                                 type="submit"
//                                 className="inline-flex w-full justify-center items-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 sm:col-start-2 disabled:opacity-25"
//                                 disabled={newEvent.mytitle === ""}
//                               >
//                                 Create
//                               </button>
//                               <button
//                                 type="button"
//                                 className="mt-3 inline-flex w-full justify-center items-center  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
//                                 onClick={handleCloseModal}
//                               >
//                                 Cancel
//                               </button>
//                             </div>
//                           </form>
//                         </div>
//                       </div>
//                     </Dialog.Panel>
//                   </Transition.Child>
//                 </div>
//               </div>
//             </Dialog>
//           </Transition.Root> */}
//         </main>
//       </div>
//     </div>
//   );
// };

// export default Calender;

import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {
  Draggable,
  DropArg,
} from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { EventSourceInput } from "@fullcalendar/core/index.js";
import AddEvent from "./Modals/AddEvent";

interface Event {
  title: string;
  start: Date | string;
  allDay: boolean;
  id: number;
  description?: string;
  location?: string;
  priority?: string;
}

const Calender = () => {
  const [events, setEvents] = useState([
    { title: "event 1", id: "1" },
    { title: "event 2", id: "2" },
    { title: "event 3", id: "3" },
    { title: "event 4", id: "4" },
    { title: "event 5", id: "5" },
  ]);
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);
  const [newEvent, setNewEvent] = useState<Event>({
    title: "",
    start: "",
    allDay: false,
    id: 0,
    description: "",
    location: "",
    priority: "",
  });

  useEffect(() => {
    let draggableEl = document.getElementById("draggable-el");
    if (
      draggableEl &&
      !draggableEl.classList.contains("draggable-initialized")
    ) {
      // Add a class to ensure it's initialized only once
      draggableEl.classList.add("draggable-initialized");

      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let id = eventEl.getAttribute("data");
          let start = eventEl.getAttribute("start");
          return { title, id, start };
        },
      });
    }
  }, [events]); // Add the dependency to ensure it runs only when events change

  function handleDateClick(arg: { date: Date; allDay: boolean }) {
    setNewEvent({
      ...newEvent,
      start: arg.date,
      allDay: arg.allDay,
      id: new Date().getTime(),
    });
    setShowModal(true);
  }

  function addEvent(data: DropArg) {
    const event = {
      ...newEvent,
      start: data.date.toISOString(),
      title: data.draggedEl.innerText,
      allDay: data.allDay,
      id: new Date().getTime(),
    };
    setAllEvents([...allEvents, event]);
  }

  function handleDeleteModal(data: { event: { id: string } }) {
    setShowDeleteModal(true);
    setIdToDelete(Number(data.event.id));
  }

  function handleDelete() {
    setAllEvents(
      allEvents.filter((event) => Number(event.id) !== Number(idToDelete))
    );
    setShowDeleteModal(false);
    setIdToDelete(null);
  }

  function handleCloseModal() {
    setShowModal(false);
    setNewEvent({
      title: "",
      start: "",
      allDay: false,
      id: 0,
    });
    setShowDeleteModal(false);
    setIdToDelete(null);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewEvent({
      ...newEvent,
      title: e.target.value,
    });
  };

  const SubmitDocument = (data: any) => {
    console.log("Date from Calender : ", data);
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setAllEvents([...allEvents, newEvent]);
    setShowModal(false);
    setNewEvent({
      title: "",
      start: "",
      allDay: false,
      id: 0,
    });
  }

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar dashboardActive="active" dashIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <h1 className="px-[16px] text-[28px] font-bold text-defaultBlue ">
          Calendar
        </h1>
        <main className="mt-6 ">
          <div className="">
            <div className="p-[16px]">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek",
                }}
                events={allEvents as EventSourceInput}
                eventContent={(arg) => (
                  <div>
                    <b>{arg.event.title}</b>
                    <p>{arg.event.extendedProps.description}</p>
                    <p>{arg.event.extendedProps.location}</p>
                    <p>{arg.event.extendedProps.priority}</p>
                  </div>
                )}
                nowIndicator={true}
                editable={true}
                droppable={true}
                selectable={true}
                selectMirror={true}
                dateClick={handleDateClick}
                drop={(data) => addEvent(data)}
                eventClick={(data) => handleDeleteModal(data)}
              />
            </div>
          </div>
          <Transition.Root show={showDeleteModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={setShowDeleteModal}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      className="relative transform overflow-hidden rounded-lg
                   bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                    >
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div
                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center 
                      justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                          >
                            <ExclamationTriangleIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              Delete Event
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete this event?
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm 
                      font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                          onClick={handleDelete}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 
                      shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* {showModal && (
            <AddEvent
              open={showModal}
              setOpenSMS={setShowModal}
              handleCloseModal={handleCloseModal}
              SubmitDocument={SubmitDocument}
            />
          )} */}
          <Transition.Root show={showModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setShowModal}>
              {/* Modal Background */}
              <Transition.Child as={Fragment}>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* Modal Content */}
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child as={Fragment}>
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Add Event
                          </Dialog.Title>

                          <form onSubmit={handleSubmit}>
                            {/* Title Input */}
                            <div className="mt-2">
                              <input
                                type="text"
                                name="title"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 
                      shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                      focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                                value={newEvent.title}
                                onChange={(e) => handleChange(e)}
                                placeholder="Title"
                              />
                            </div>

                            {/* Description Input */}
                            <div className="mt-2">
                              <input
                                type="text"
                                name="description"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                      ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                      focus:ring-violet-600 sm:text-sm sm:leading-6"
                                value={newEvent.description}
                                onChange={(e) =>
                                  setNewEvent({
                                    ...newEvent,
                                    description: e.target.value,
                                  })
                                }
                                placeholder="Description"
                              />
                            </div>

                            {/* Location Input */}
                            <div className="mt-2">
                              <input
                                type="text"
                                name="location"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm 
                      ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                      focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                                value={newEvent.location}
                                onChange={(e) =>
                                  setNewEvent({
                                    ...newEvent,
                                    location: e.target.value,
                                  })
                                }
                                placeholder="Location"
                              />
                            </div>

                            {/* Priority Input */}
                            <div className="mt-2">
                              <input
                                type="text"
                                name="priority"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm 
                      ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                      focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                                value={newEvent.priority}
                                onChange={(e) =>
                                  setNewEvent({
                                    ...newEvent,
                                    priority: e.target.value,
                                  })
                                }
                                placeholder="Priority"
                              />
                            </div>

                            <div className="mt-5 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="submit"
                                className="inline-flex w-full justify-center items-center rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 sm:col-start-2 disabled:opacity-25"
                                disabled={newEvent.title === ""}
                              >
                                Create
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                onClick={handleCloseModal}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </main>
      </div>
    </div>
  );
};

export default Calender;
