import { Typography } from "../../../components/typography/Typography";
import { MingoInput } from "../../../components/inputs/MingoInput";
import { Col, Row } from "react-bootstrap";
import ImgUploadComponent from "./ImgUploadComponent";

const LandingPage = () => {
  return (
    <div className="pb-[40px] rounded-[20px] bg-white px-[30px] py-[20px] shadow-sm text-defaultText">
      <Typography.RegularText text="Class Landing page" />

      {/* upload image section */}
      <ImgUploadComponent />

      <div className="mt-[30px] text-defaultText">
        <Row className="mt-[10px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Class Name" />
          </Col>
          <Col md={8}>
            <MingoInput.Cornered type="text" placeholder="Type here..." />
          </Col>
        </Row>
        <Row className="mt-[10px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Category" />
          </Col>
          <Col md={8}>
            <MingoInput.Cornered type="text" placeholder="Type here..." />
          </Col>
        </Row>
        <Row className="mt-[10px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Program" />
          </Col>
          <Col md={8}>
            <MingoInput.OptionsSelect />
          </Col>
        </Row>
        <Row className="mt-[10px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Class ID" />
          </Col>
          <Col md={8}>
            <MingoInput.Cornered type="text" placeholder="Type here..." />
          </Col>
        </Row>
        <Row className="mt-[10px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Grade" />
          </Col>
          <Col md={8}>
            <MingoInput.OptionsSelect />
          </Col>
        </Row>
        <Row className="mt-[10px]">
          <Col md={4}>
            <Typography.MediumText text="Description" />
          </Col>
          <Col md={8}>
            <MingoInput.TextArea type="text" placeholder="Type here..." />
          </Col>
        </Row>
        <Row className="mt-[10px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Language of Instruction" />
          </Col>
          <Col md={8}>
            <MingoInput.OptionsSelect />
          </Col>
        </Row>
        <Row className="mt-[10px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Level" />
          </Col>
          <Col md={8}>
            <MingoInput.Cornered type="text" placeholder="Type here..." />
          </Col>
        </Row>
        {/* <Row className="mt-[10px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Academic Term" />
          </Col>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <MingoInput.Cornered type="date" label="Starts on" />
              </Col>
              <Col md={6}>
                <MingoInput.Cornered type="date" label="Ends on" />
              </Col>
            </Row>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default LandingPage;
