import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import BaseService from "../../../helpers/baseServices";

interface theProps {
  onChange: (selected: string) => void;
  schoolId?: string;
}

interface SchoolProps {
  label: string;
  value: string;
}

const SchoolDs = ({ onChange, schoolId }: theProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allApprovedSchools, setAllApprovedSchools] = useState<SchoolProps[]>(
    []
  );
  const [selectedApprovedSchool, setSelectedApprovedSchool] = useState<string>(
    schoolId || ""
  );

  const fetchApprovedSchools = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await BaseService.get_api(
        `/robocentre/roboschool/onboarding/school?filter=${encodeURIComponent(
          JSON.stringify({ approved: true })
        )}`
      );

      if (data?.payload) {
        const arrangedResponse: SchoolProps[] = data.payload.map(
          (item: any) => ({
            label: item?.schoolInformation?.schoolName,
            value: item._id,
          })
        );
        setAllApprovedSchools(arrangedResponse);
      }
    } catch (error) {
      console.error("Failed to fetch schools:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchApprovedSchools();
  }, [fetchApprovedSchools]);

  useEffect(() => {
    if (schoolId) {
      setSelectedApprovedSchool(schoolId);
    }
  }, [schoolId]);

  const handleChange = useCallback(
    (value: string) => {
      setSelectedApprovedSchool(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className="flex items-center gap-4 justify-between">
      <label className="w-[200px] block text-[#0F0F37] mb-1">School</label>
      <Select
        id="category"
        value={selectedApprovedSchool || undefined}
        className="h-[48px] w-full"
        options={allApprovedSchools}
        onChange={handleChange}
        disabled={isFetching}
        loading={isFetching}
        placeholder="Select approved School"
        allowClear
      />
    </div>
  );
};

export default SchoolDs;
