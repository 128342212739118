import React, { useState } from "react";
import EditAcadamic from "./EditAcadamic";

const AcadamicUI = ({
  gradeLevelData,
  schoolName,
}: {
  gradeLevelData: any;
  schoolName: any;
}) => {
  const [open, setOpen] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div className="p-4 text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[25px] my-4 bg-white">
      <section className="flex justify-between">
        <section className="flex w-full justify-between">
          <p className="text-[20px] text-text_deep mb-2 font-medium ">
            Academic year
          </p>
          <EditAcadamic
            showModal={showModal}
            open={open}
            setOpen={setOpen}
            handleReFetch={() => setReFetch(!reFetch)}
          />
        </section>
      </section>
      <div className="">
        <div className="h-full  ">
          <div className="bg-white rounded-[20px] ">
            <section className="flex justify-between gap-10">
              <div className="flex mt-10 text-defaultBlue justify-between gap-6">
                <p className="text-[24px] font-bold">{schoolName}</p>
                <p className="text-[24px] font-bold">{gradeLevelData}</p>
              </div>
            </section>

            <div className="rounded-[10px] h-[100px]  mt-10 bg-[#6666EA33]">
              <div className="bg-[#6666EA] text-white flex items-center h-full w-[70%] px-4 rounded-[10px] rounded-tr-none rounded-br-none">
                <p>
                  <p>
                    <span>Term 1</span>{" "}
                    <span className="bg-[#FFC400] px-3 ml-5 rounded-full">
                      Current
                    </span>
                  </p>
                  <p>Nov. 2022 - Sept 2023</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcadamicUI;
