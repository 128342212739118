import { Typography } from "../../../components/typography/Typography";
import { MingoInput } from "../../../components/inputs/MingoInput";

const MessagesPage = () => {
  return (
    <div className="pb-[40px] rounded-[20px] bg-white px-[30px] py-[20px] shadow-sm text-defaultText">
      <Typography.RegularText text="Class Messages" />
      <hr />

      <div className="mt-[20px]">
        <div>
          <MingoInput.TextArea
            type="text"
            placeholder="Type here..."
            label="Welcome Message"
          />
        </div>
        <div className="mt-[20px]">
          <MingoInput.TextArea
            type="text"
            placeholder="Type here..."
            label="Congratulations Message"
          />
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
