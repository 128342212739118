import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import BaseService from "../../../helpers/baseServices";

interface theProps {
  onChange: (selected: string[]) => void;
  schoolId?: string[];
}

interface GradeLevelProps {
  label: string;
  value: string;
}

const SelectSchools = ({ onChange, schoolId }: theProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allApprovedSchools, setAllApprovedSchools] = useState<
    GradeLevelProps[]
  >([]);
  const [selectedApprovedSchools, setSelectedApprovedSchools] = useState<
    string[]
  >(schoolId || []);

  const fetchApprovedSchools = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await BaseService.get_api(
        `/robocentre/roboschool/onboarding/school?filter=${encodeURIComponent(
          JSON.stringify({ approved: true })
        )}`
      );

      if (data?.payload) {
        const arrangedResponse: GradeLevelProps[] = data.payload.map(
          (item: any) => ({
            label: item?.schoolInformation?.schoolName,
            value: item._id,
          })
        );
        setAllApprovedSchools(arrangedResponse);
      }
    } catch (error) {
      console.error("Failed to fetch schools:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchApprovedSchools();
  }, [fetchApprovedSchools]);

  // Update selected schools when schoolId prop changes
  useEffect(() => {
    if (schoolId && schoolId.length > 0) {
      setSelectedApprovedSchools(schoolId);
    }
  }, [schoolId]);

  const handleChange = useCallback(
    (value: string[]) => {
      setSelectedApprovedSchools(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <div>
      <label className="block text-[#0F0F37] mb-1">Select Schools</label>
      <Select
        id="category"
        value={selectedApprovedSchools}
        className="h-[48px] w-full"
        options={allApprovedSchools}
        onChange={handleChange}
        disabled={isFetching}
        loading={isFetching}
        placeholder="Select approved Schools"
        allowClear
        mode="multiple"
      />
    </div>
  );
};

export default SelectSchools;
