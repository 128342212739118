import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import settingsImg from "../../images/settingsImg.png";
import quizImg from "../../images/quizImg.png";
import { Col, Image, Row } from "react-bootstrap";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import {
  BellAlertIcon,
  DocumentChartBarIcon,
  EyeIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { NormalButtons } from "../../components/buttons/NormalButtons";
import SettingsTab from "./tabs/SettingsTab";
import QuizTab from "./tabs/QuizTab";
import { useNavigate } from "react-router-dom";

const AddQuiz = () => {
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "Settings",
      icon: <Image className="" src={settingsImg} alt="..." fluid />,
    },
    {
      name: "Quiz",
      icon: <Image className="" src={quizImg} alt="..." fluid />,
    },
  ];

  //Tab active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === tabActive) {
      return "w-[300px] h-[60px] flex gap-[10px] items-center pl-[15px] bg-gradient-to-b from-gradient-deepPinkStart to-gradient-deepPinkEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 1 && i === tabActive) {
      return "w-[300px] h-[60px] flex gap-[10px] items-center pl-[15px] bg-gradient-to-b from-gradient-blueEnd to-gradient-blueStart text-defaultWhite shadow-sm rounded-[20px]";
    } else {
      return "w-[300px] h-[60px] flex gap-[10px] items-center pl-[15px] text-defaultText bg-defaultWhite shadow-sm rounded-[20px]";
    }
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Create Quiz" />

          <Row>
            {/* left side */}
            <Col md={9} className="mt-[10px]">
              <div className="mt-[5px]">
                <Tabs>
                  <TabList
                    className="flex gap-[12px] mb-[20px] pb-2 overflow-x-auto"
                    id="horizontalTab"
                  >
                    {tabNames.map((item, index) => (
                      <Tab key={index} className="bg-transparent">
                        <button
                          onClick={setTabActive.bind(this, index)}
                          key={index}
                          className={showActive(index)}
                        >
                          {item.icon}
                          <Typography.MediumText text={item.name} />
                        </button>
                      </Tab>
                    ))}
                  </TabList>

                  <hr className="mb-[20px]" />

                  <TabPanel>
                    <SettingsTab />
                  </TabPanel>
                  <TabPanel>
                    <QuizTab />
                  </TabPanel>
                </Tabs>
              </div>
            </Col>

            {/* right side */}
            <Col md={3}>
              <div>
                <NormalButtons.FilledWithIcon
                  btnName="Assign Quiz"
                  icon={<UserPlusIcon className="w-5 h-5" />}
                  onClick={() => alert("Assign!!")}
                />
              </div>
              <div className="mt-5">
                <NormalButtons.WhiteBackground
                  btnName="Preview Quiz"
                  icon={<EyeIcon className="w-5 h-5" />}
                  onClick={() => navigate("/preview_quiz")}
                />
              </div>
              <div className="mt-2">
                <NormalButtons.WhiteBackground
                  btnName="Send Notification"
                  icon={<BellAlertIcon className="w-5 h-5" />}
                  onClick={() => alert("Clicked!!")}
                />
              </div>
              <div className="mt-2">
                <NormalButtons.WhiteBackground
                  btnName="Show Results"
                  icon={<DocumentChartBarIcon className="w-5 h-5" />}
                  onClick={() => alert("Clicked!!")}
                />
              </div>
              <div className="mt-5 text-defaultText">
                <Typography.MediumText
                  text="Created By:"
                  xStyle="font-semibold"
                />
                <Typography.MediumText text="Mawuli Togbor" />
              </div>
              <div className="mt-2 text-defaultText">
                <Typography.MediumText
                  text="Last Updated:"
                  xStyle="font-semibold"
                />
                <Typography.MediumText text="May 24, 2023 - 5:40 AM  " />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddQuiz;
