import { Col, Row } from "react-bootstrap";
import { Typography } from "../../../../components/typography/Typography";

const Overview = () => {
  return (
    <div className="w-full shadow-sm rounded-[20px] bg-white px-[20px] pt-[20px] pb-[30px]">
      <Typography.MediumText text="Personal Details" xStyle="font-semibold" />

      <hr className="mt-2" />

      <Row>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="First Name" />
          <Typography.MediumText text="Vivian" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Prefered Name" />
          <Typography.MediumText text="Lorem Ipsum" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Contact 1" />
          <Typography.MediumText text="+233000221456" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Last Name" />
          <Typography.MediumText text="Togbor" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Date of Birth" />
          <Typography.MediumText text="28/05/2016" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Parent ID" />
          <Typography.MediumText text="RCS848480289" />
        </Col>
      </Row>

      <Typography.MediumText
        text="Contact Details"
        xStyle="font-semibold mt-[30px]"
      />

      <hr className="mt-2" />

      <Row>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="First Name" />
          <Typography.MediumText text="Vivian" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Prefered Name" />
          <Typography.MediumText text="Lorem Ipsum" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Contact 1" />
          <Typography.MediumText text="+233000221456" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Last Name" />
          <Typography.MediumText text="Togbor" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Date of Birth" />
          <Typography.MediumText text="28/05/2016" />
        </Col>
        <Col md={4} className="pt-[20px]">
          <Typography.SmallText text="Parent ID" />
          <Typography.MediumText text="RCS848480289" />
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
