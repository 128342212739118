import React from "react";
import {
  MagnifyingGlassIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import DataTable from "react-data-table-component";
import { Dropdown, Image } from "react-bootstrap";
import moment from "moment";
import { Typography } from "../../../../components/typography/Typography";
import { MingoInput } from "../../../../components/inputs/MingoInput";

const InstructorsTable = () => {
  //dummy data
  const dummyData = [
    {
      name: "John Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      studentId: "Early",
      date: "2023-04-03T14:57:58.088Z",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      studentId: "Late",
      date: "2023-04-03T14:57:58.088Z",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      studentId: "Late",
      date: "2023-04-03T14:57:58.088Z",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      studentId: "Late",
      date: "2023-04-03T14:57:58.088Z",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      studentId: "Late",
      date: "2023-04-03T14:57:58.088Z",
    },
  ];
  //Table columns
  const columns = [
    {
      name: (
        <Typography.MediumText
          text="Name"
          xStyle="text-defaultBlue font-semibold"
        />
      ),
      cell: (row: any) => (
        <div className="flex gap-[10px] items-center">
          <Image
            className="w-[32px] h-[32px] rounded-full"
            src={row.image}
            alt="..."
            fluid
          />
          <Typography.MediumText text={row.name} xStyle="text-defaultText" />
        </div>
      ),
      width: "30%",
    },
    {
      name: (
        <Typography.MediumText
          text="Last Seen"
          xStyle="text-defaultBlue font-semibold"
        />
      ),
      cell: (row: any) => (
        <Typography.MediumText
          text={moment(row.date).format("Do MMM YYYY")}
          xStyle="text-defaultText"
        />
      ),
      width: "30%",
    },
    {
      name: (
        <Typography.MediumText
          text="Student ID"
          xStyle="text-defaultBlue font-semibold"
        />
      ),
      cell: (row: any) => (
        <Typography.MediumText text={row.studentId} xStyle="text-defaultText" />
      ),
      width: "20%",
    },
    {
      name: "",
      cell: (row: any) => (
        <Dropdown className="relative">
          <Dropdown.Toggle as={CustomToggle} />

          <Dropdown.Menu className="absolute right-0 bottom-0 z-10">
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      width: "10%",
    },
  ];

  return (
    <div>
      <div className="mt-[20px] relative">
        <MingoInput.Rounded
          type="text"
          placeholder="Search and add instructors to class"
        />
        <MagnifyingGlassIcon className="w-5 h-5 text-defaultBlue_40 absolute bottom-[10px] right-0 mr-3 cursor-pointer" />
      </div>
      {/* table */}
      <div className="mt-[20px] border-[1px] border-defaultBlue_40 rounded-[20px]">
        <DataTable
          responsive
          progressComponent="Loading..."
          // progressPending={isFetching}
          data={dummyData}
          columns={columns}
          selectableRows
          onSelectedRowsChange={(dd) => console.log(dd)}
          paginationServer
          noDataComponent={
            <div>
              <p>No data to display</p>
            </div>
          }
        />
      </div>
    </div>
  );
};

const CustomToggle = React.forwardRef((onClick: any) => (
  <EllipsisVerticalIcon
    className="h-6 w-6 text-defaultBlue font-semibold cursor-pointer"
    onClick={onClick}
  />
));

export default InstructorsTable;
