import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const data = {
  labels: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Reading",
      backgroundColor: "#6666EA",
      data: [22, 59, 30, 50, 38, 42, 39],
      barPercentage: 0.08,
      borderRadius: 10,
    },
    {
      label: "Video",
      backgroundColor: "#FFC400",
      data: [22, 59, 30, 56, 50, 42, 19],
      barPercentage: 0.08,
      borderRadius: 10,
    },
    {
      label: "Coding",
      backgroundColor: "#FF365D",
      data: [22, 59, 30, 42, 28, 42, 49],
      barPercentage: 0.08,
      borderRadius: 10,
    },
    {
      label: "Assignment",
      backgroundColor: "#15C2A5",
      data: [22, 59, 30, 35, 48, 42, 29],
      barPercentage: 0.08,
      borderRadius: 10,
    },
  ],
};

const config = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      textAlign: "right",
      margin: "2rem",
      labels: {
        usePointStyle: true,
        pointRadius: 5, // set the radius of the point
        pointStyle: "rect", // set the style of the point
        pointStyleWidth: 10, // set the width of the point
        pointStyleHeight: 5,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      position: "top",
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
      },
      type: "linear",
      display: false,
    },
  },
  tooltips: {
    displayColors: true,
    callbacks: {
      mode: "x",
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

export const StackedChart = () => {
  return (
    <div style={{ height: "260px" }}>
      <Bar data={data} options={config} />
    </div>
  );
};
