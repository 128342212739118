import { Typography } from "../../../../../components/typography/Typography";
import { Col, Row } from "react-bootstrap";
import { MingoInput } from "../../../../../components/inputs/MingoInput";
import NestedCheckbox from "../../../../../components/inputs/NestedCheckbox";

const Cover = () => {
  const handleCheckboxChange = (checkedItems: any) => {
    // console.log(checkedItems);
  };

  //checkbox list options
  const options = [
    {
      label: "Student Name",
      value: "Student Name",
      options: [
        {
          label: "Student ID",
          value: "Student ID",
        },
        {
          label: "First Name",
          value: "First Name",
        },
        {
          label: "Middle Name",
          value: "Middle Name",
        },
        {
          label: "Last Name",
          value: "Last Name",
        },
      ],
    },
    {
      label: "Student Photo",
      value: "Student Photo",
    },
    {
      label: "Date of Birth",
      value: "Date of Birth",
    },
    {
      label: "Email",
      value: "Email",
    },
    {
      label: "Level",
      value: "Level",
      options: [
        {
          label: "Year / Grade",
          value: "Year / Grade",
        },
        {
          label: "Module",
          value: "Module",
        },
        {
          label: "Cohort",
          value: "Cohort",
        },
      ],
    },
    {
      label: "Instructor(s) Name",
      value: "Instructor(s) Name",
    },
    {
      label: "Preparation Date",
      value: "Preparation Date",
    },
  ];

  return (
    <div>
      <Typography.MediumText text="Please use the checkboxes to indicate whether you would like to include each particular field on your cover sheet." />

      <div className="mt-[25px]">
        <Row>
          <Col md={3}>
            <Typography.MediumText text="Cover Details" />
          </Col>
          <Col md={9}>
            <div className="mb-3">
              <NestedCheckbox
                options={options}
                onChange={handleCheckboxChange}
                checked={false}
              />
            </div>
          </Col>
        </Row>

        <hr className="mt-[10px] mb-[30px]" />

        <Row>
          <Col md={3}>
            <Typography.MediumText text="Signer Details" />
          </Col>
          <Col md={9}>
            <div className="my-4">
              <Row>
                <Col md={3}>
                  <Typography.MediumText text="Name" />
                </Col>
                <Col md={9}>
                  <MingoInput.Cornered type="text" placeholder="Type here..." />
                </Col>
              </Row>
              <Row className="mt-[10px]">
                <Col md={3}>
                  <Typography.MediumText text="Position" />
                </Col>
                <Col md={9}>
                  <MingoInput.Cornered type="text" placeholder="Type here..." />
                </Col>
              </Row>
              <Row className="mt-[10px]">
                <Col md={3}>
                  <Typography.MediumText text="Signature" />
                </Col>
                <Col md={9}>
                  <MingoInput.ImageSelect />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Cover;
