import { Col, Row } from "react-bootstrap";
import { Typography } from "../../../../../components/typography/Typography";
import { MingoInput } from "../../../../../components/inputs/MingoInput";

const Summary = () => {
  return (
    <div>
      <Row>
        <Col md={3}>
          <Typography.MediumText text="Summary Page Name" />
        </Col>
        <Col md={9}>
          <MingoInput.Cornered type="text" placeholder="Type here ..." />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={3}>
          <Typography.MediumText text="Summary Page Name" />
        </Col>
        <Col md={9}>
          <div className="mb-2">
            <MingoInput.CheckBox label="Grade" />
          </div>
          <MingoInput.OptionsSelect
            label="Display As"
            options={<option value="Grade Report">Grade Report</option>}
          />
          <div className="mb-2 mt-3">
            <MingoInput.CheckBox label="Final Grade" />
          </div>
          <div className="mb-2">
            <MingoInput.CheckBox label="Show Weight" />
          </div>
          <div className="mb-2">
            <MingoInput.CheckBox label="Progress Summary" />
          </div>
          <div className="mb-2">
            <MingoInput.CheckBox label="Percentage" />
          </div>
          <div className="mb-2">
            <MingoInput.CheckBox label="Point" />
          </div>
          <div className="mb-2">
            <MingoInput.CheckBox label="Student Name" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
