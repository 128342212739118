import { Col, Form, Image, Row } from "react-bootstrap";
import { Typography } from "../../../../components/typography/Typography";
import LatestResultsCard from "./LatestResultsCard";
import PerformanceCard from "./PerformanceCard";
import { StackedChart } from "./StackedChart";

const Dashboard = () => {
  return (
    <div>
      <Row>
        <Col md={4}>
          <PerformanceCard
            title="Performance"
            number="240"
            bottomPercentage="2.5%"
            bottomText="more than last month"
            icon={<Image src="" alt="..." fluid />}
          />
        </Col>
        <Col md={4}>
          <PerformanceCard
            title="Completed Tasks"
            number="240"
            bottomPercentage="2.5%"
            bottomText="more than last month"
            icon={<Image src="" alt="..." fluid />}
          />
        </Col>
        <Col md={4}>
          <PerformanceCard
            title="Total Learning Hours"
            number="240"
            bottomPercentage="2.5%"
            bottomText="more than last month"
            icon={<Image src="" alt="..." fluid />}
          />
        </Col>
      </Row>

      <Row>
        <Col className="mt-[33px]" md={5}>
          <LatestResultsCard />
        </Col>

        <Col className="mt-[33px]" md={7}>
          <div className="border-0 shadow-sm rounded-[20px] p-[22px] h-[320px] bg-defaultWhite">
            <div className="flex justify-between">
              <Typography.MediumText
                text="Time Spent on Learning"
                xStyle="font-semibold"
              />
              <Form.Select
                aria-label="Default select example"
                className="w-[120px] text-[16px] text-defaultBlue border-none shadow-none cursor-pointer font-semibold"
              >
                <option value="first">This Week</option>
                <option value="second">This Month</option>
                <option value="third">This Year</option>
              </Form.Select>
            </div>

            <StackedChart />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
