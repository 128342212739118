import React, { useState } from "react";
import { Typography } from "../../../components/typography/Typography";
import quizImgSmall from "../../../images/quizImgSmall.png";
import { Image } from "react-bootstrap";
import QuestionComponent from "../QuestionComponent";
import {
  PlusCircleIcon,
  ClipboardIcon,
  QuestionMarkCircleIcon,
  PrinterIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";
import { v4 as uuidv4 } from "uuid";

interface compoProps {
  id: string;
  component: JSX.Element;
}

const QuizTab = () => {
  const [components, setComponents] = useState<compoProps[]>([]);

  const handleAddNewComponent = () => {
    const newComponent = {
      id: uuidv4(),
      component: <QuestionComponent key={uuidv4()} onDelete={handleDelete} />,
    };
    setComponents([...components, newComponent]);
  };

  const handleDelete = (id: any) => {
    setComponents(components.filter((component: any) => component.id !== id));
  };

  return (
    <>
      <div className="w-full h-auto pb-[30px] rounded-[20px] shadow-sm bg-white text-defaultText pt-[20px] px-[30px]">
        <div className="flex justify-between items-center">
          <div className="flex gap-[15px] items-center">
            <Image src={quizImgSmall} alt="..." fluid />

            <Typography.MediumText
              text="End of Term 3 Quiz"
              xStyle="font-semibold"
            />
          </div>
          <Typography.SmallText text="10 marks" />
        </div>

        <Typography.MediumText
          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
          xStyle="mt-[10px]"
        />
      </div>

      {/* questions component */}
      <div className="mt-[12px] relative">
        {components.map(({ id, component }) => (
          <div key={id}>
            {React.cloneElement(component, {
              onDelete: () => handleDelete(id),
            })}
          </div>
        ))}

        {/* bottom actions nav */}
        <div className="absolute right-0 flex items-center gap-[18px] bg-defaultBlue px-4 py-2 rounded-b-[20px]">
          <button onClick={handleAddNewComponent}>
            <PlusCircleIcon className="w-5 h-5 text-white" />
          </button>
          <button>
            <LinkIcon className="w-5 h-5 text-white" />
          </button>
          <button>
            <ClipboardIcon className="w-5 h-5 text-white" />
          </button>
          <button>
            <PrinterIcon className="w-5 h-5 text-white" />
          </button>
          <button>
            <QuestionMarkCircleIcon className="w-5 h-5 text-white" />
          </button>
        </div>
      </div>
    </>
  );
};

export default QuizTab;
