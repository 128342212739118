import {
  ChatBubbleLeftIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { Image } from "react-bootstrap";
import { Typography } from "../../../../components/typography/Typography";

const Messages = () => {
  return (
    <>
      <div className="bg-defaultWhite shadow-sm rounded-[20px] pt-[15px] pb-[30px] pl-[30px] pr-[200px] text-defaultText">
        <Typography.RegularText text="Messages" xStyle="font-semibold" />

        <div className="mt-[18px]">
          <SampleMessage />
          <SampleMessage />
          <SampleMessage />
        </div>
      </div>
    </>
  );
};

//data mapper
const SampleMessage = () => {
  return (
    <>
      <div className="mt-[25px] rounded-[20px] border border-defaultBlue_5 p-3 shadow-sm">
        <div className="flex justify-between">
          <div className="flex gap-[22px]">
            <Image
              className="h-[50px] w-[50px] rounded-full object-cover"
              src="https://i.pinimg.com/736x/b9/fa/d8/b9fad8f4fb41fd671f3a8f308f3c85c5.jpg"
              alt="..."
              fluid
            />
            <div>
              <Typography.MediumText
                text="Lorem ipsum dolor"
                xStyle="text-defaultText font-semibold"
              />
              <Typography.SmallText
                text="Instructor"
                xStyle="text-defaultText opacity-[60%]"
              />
            </div>
          </div>
          <div>
            <Typography.SmallText
              text="2 days ago"
              xStyle="text-defaultText opacity-[60%]"
            />
          </div>
        </div>
        <div className="text-defaultText mt-[20px]">
          <Typography.MediumText
            text="Welcome to Introduction to Robotics"
            xStyle="font-medium text-defaultBlue"
          />
          <Typography.MediumText
            text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita"
          />

          <div className="mt-[20px] flex gap-[40px]">
            <button className="text-defaultBlue flex gap-[14px] justify-center items-center font-medium">
              <HandThumbUpIcon className="w-4 h-4" />
              <Typography.MediumText text="Like" />
            </button>
            <button className="text-defaultBlue flex gap-[14px] justify-center items-center font-medium">
              <ChatBubbleLeftIcon className="w-4 h-4" />
              <Typography.MediumText text="Reply" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
