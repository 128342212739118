import React, { useState } from "react";
import { Accordion, Dropdown, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ClockIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Typography } from "../../../../components/typography/Typography";

const CurrentCourseAccordion = () => {
  const [activeKey, setActiveKey] = useState(null);

  // custom dropdown
  const CustomToggle = React.forwardRef((onClick: any, ref) => (
    <Link className="w-[24px] h-[24px]" to="#" onClick={onClick}>
      <EllipsisVerticalIcon className="w-5 h-5 text-defaultBlue" />
    </Link>
  ));

  return (
    <Accordion activeKey={activeKey} onSelect={(i: any) => setActiveKey(i)}>
      <Accordion.Item
        eventKey="0"
        className="bg-defaultBlue_5 border-[1px] border-defaultBlue_40"
        id="customArccordionItem"
      >
        <Accordion.Header
          className={activeKey === "0" ? "bg-defaultBlue_5 border-[1px]" : ""}
        >
          <div className="flex gap-[70px]">
            {/* left flex*/}
            <div className="flex gap-[20px] j items-center">
              <Image
                className="w-[91px] h-[86px] rounded-[12px] object-cover"
                src="https://www.rd.com/wp-content/uploads/2022/09/GettyImages-1356817526-e1663095877427.jpg"
                alt="..."
                fluid
              />
              <div>
                <Typography.MediumText
                  text="Basic Electronics"
                  xStyle="font-semibold text-defaultBlue"
                />
                <div className="mt-[15px] flex gap-[10px]">
                  <p className="bg-defaultBlue_60 text-defaultWhite px-[6px] py-[1px] rounded-[20px] text-[12px]">
                    Starts: Week 3 May
                  </p>
                  <p className="bg-tertiary-pink text-defaultWhite px-[6px] py-[1px] rounded-[20px] text-[12px]">
                    16 weeks
                  </p>
                </div>
                <div className="w-full mt-[15px]">
                  <div className="bg-gray-200 h-[3px] rounded-[15px]">
                    <div
                      className="bg-defaultYellow h-[3px] rounded-[15px]"
                      style={{ width: `20%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {/* right flex*/}
            <div className="flex gap-[60px] justify-center items-center">
              <div className="text-center">
                <Typography.HeadingThree
                  text="8"
                  xStyle="font-semibold text-defaultGreen"
                />
                <Typography.MediumText text="Units" xStyle="text-defaultText" />
              </div>
              <div className="text-center">
                <Typography.HeadingThree
                  text="6"
                  xStyle="font-semibold text-defaultYellow"
                />
                <Typography.MediumText text="Tasks" xStyle="text-defaultText" />
              </div>
              <div className="text-center">
                <Typography.HeadingThree
                  text="100"
                  xStyle="font-semibold text-defaultBlue"
                />
                <Typography.MediumText
                  text="Max Points"
                  xStyle="text-defaultText"
                />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="text-defaultText">
            <Typography.MediumText
              text="Units and tasks"
              xStyle="font-semibold"
            />

            {/* unit card */}
            <div className="bg-defaultWhite border-[1px] border-defaultBlue_40 rounded-[20px] mt-[10px] py-[13px] px-[20px]">
              {/* introduction */}
              <div>
                <Typography.MediumText
                  text="Unit 1: Introduction To Snap Components"
                  xStyle="font-semibold text-defaultBlue"
                />

                <div className="flex gap-[12px] items-center">
                  <p className="bg-tertiary-pink text-defaultWhite px-[6px] py-[1px] rounded-[20px] text-[10px]">
                    1 week
                  </p>
                  <p className="bg-defaultYellow text-defaultWhite px-[6px] py-[1px] rounded-[20px] text-[10px]">
                    Current
                  </p>
                </div>

                {/* task */}
                <div className="bg-defaultWhite border-[1px] border-defaultBlue_40 h-[66px] rounded-[20px] mt-[20px] py-[10px] px-[20px]">
                  <div className="flex justify-between items-center">
                    {/* left */}
                    <div className="flex gap-[20px] items-center">
                      <div className="w-[38px] h-[40px] rounded-[10px] bg-defaultBlue_20 flex flex-col justify-center items-center">
                        <div className="bg-defaultBlue text-defaultWhite w-full h-[16px] rounded-full flex justify-center items-center">
                          <Typography.SmallText text="Feb" />
                        </div>
                        <Typography.MediumText
                          text="24"
                          xStyle="text-defaultBlue"
                        />
                      </div>

                      <div>
                        <Typography.MediumText
                          text="Lecture 1"
                          xStyle="text-defaultBlue font-semibold"
                        />

                        <div className="flex gap-[8px] item-center">
                          <p className="bg-tertiary-pink text-defaultWhite text-center h-[17px] font-medium text-[12px] rounded-[20px] w-auto px-[6px]">
                            Homework
                          </p>

                          <ClockIcon className="w-5 h-5 text-defaultYellow" />

                          <Typography.SmallText
                            text="8:00 AM - 4:00 PM"
                            xStyle="font-medium text-defaultGray"
                          />
                        </div>
                      </div>
                    </div>

                    {/* right */}

                    <div className="flex gap-[20px] items-center">
                      <Image
                        className="w-[24px] h-[24px] object-cover rounded-full shadow-sm"
                        src="https://st2.depositphotos.com/2208684/5796/i/600/depositphotos_57968153-stock-photo-teacher-in-front-of-black.jpg"
                        alt="..."
                        fluid
                      />

                      <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} />
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      {/* <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item> */}
    </Accordion>
  );
};

export default CurrentCourseAccordion;
