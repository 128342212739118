import CourseIDs from "../../../Calender/FormElements/CourseIDs";
import SchoolDs from "../../../Calender/FormElements/SchoolDs";
import GradeLevel from "../../../Calender/FormElements/GradeLevel";
import NewDatePickerRange from "../../../Calender/FormElements/NewDatePickerRange";
import AcademicIDs from "../../../Calender/FormElements/AcademicIDs";
import { Button, notification } from "antd";
import { useEffect, useReducer, useState } from "react";
import BaseService from "../../../../helpers/baseServices";
import AcadamicUI from "./AcadamicUI";
import { getCookie, setCookie } from "../../../../helpers/utils";

type Action =
  | { type: "SET_COURSE_ID"; payload: string }
  | { type: "SET_ACADEMIC_ID"; payload: string }
  | { type: "SET_SCHOOL_ID"; payload: string }
  | { type: "SET_GRADE_ID"; payload: string }
  | { type: "SET_MEETING_ID"; payload: any }
  | { type: "SET_FORM_VALIDITY"; payload: boolean };

const initialState = {
  courseId: "",
  acadermictermId: "",
  schoolId: "",
  gradeLevel: "",
  meetings: [],
  isFormValid: false,
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case "SET_COURSE_ID":
      return { ...state, courseId: action.payload };
    case "SET_ACADEMIC_ID":
      return { ...state, acadermictermId: action.payload };
    case "SET_SCHOOL_ID":
      return { ...state, schoolId: action.payload };
    case "SET_GRADE_ID":
      return { ...state, gradeLevel: action.payload };
    case "SET_MEETING_ID":
      return { ...state, meetings: action.payload };

    case "SET_FORM_VALIDITY":
      return { ...state, isFormValid: action.payload };
    default:
      return state;
  }
};

const AcademicsTab = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [singleSchoolID, setSingleSchoolID] = useState<any>();
  const [isLoadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [schoolGradeValues, setSchoolGradeValues] = useState<any>();
  const [schoolName, setSchoolName] = useState<any>();
  const [gradeLevelData, setgradeLevelData] = useState<any>();
  const [VerifySchoolId, setVerifySchoolId] = useState<any>(null);
  const [isCookieSet, setIsCookieSet] = useState<boolean>(false);

  useEffect(() => {
    const { courseId, acadermictermId, schoolId, gradeLevel, meetings } = state;
    const isFormValid =
      courseId.trim() !== "" &&
      acadermictermId.trim() !== "" &&
      schoolId !== "" &&
      gradeLevel !== "" &&
      meetings.length > 0;

    if (state.isFormValid !== isFormValid) {
      dispatch({ type: "SET_FORM_VALIDITY", payload: isFormValid });
    }
  }, [state]);

  const handleInputCourseChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };
  const handleSchoolInputChange = (type: Action["type"], value: any) => {
    console.log("School Selected : ", value);
    setSingleSchoolID(value);
    dispatch({ type, payload: value });
  };
  const handleInputGradeChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };
  const handleInputAcademicChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };
  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    dispatch({ type: "SET_MEETING_ID", payload: [{ start, end }] });
  };

  const handleSubmit = async () => {
    try {
      const data = {
        courseId: state.courseId,
        acadermictermId: state.acadermictermId,
        schoolId: state.schoolId,
        gradeLevel: state.gradeLevel,
        meetings: state.meetings,
      };
      const response = await BaseService.post_api(
        `/robocentre/roboinstructor/setup/calender`,
        data
      );

      console.log("API Response: ", response.data);

      const { payload } = response.data;
      notification.success({
        message: `Calendar information created successfully`,
      });
      if (payload && payload.schoolId && payload.gradeLevel) {
        setSchoolGradeValues({
          schoolId: payload.schoolId,
          gradeLevel: payload.gradeLevel,
        });
        setCookie(
          "filterData",
          JSON.stringify({
            schoolId: payload.schoolId,
            gradeLevel: payload.gradeLevel,
          }),
          365
        );
        setLoadingDetails(true);
        setgradeLevelData(payload?.geadeLevelData?.name);
        setSchoolName(payload?.schoolData?.schoolInformation?.schoolName);
        setIsCookieSet(true);
      } else {
        console.log("Invalid data in response");
      }
    } catch (error: any) {
      notification.error({
        message: ` ${error?.response?.data?.error}` || "Error Occurred",
      });
      setLoadingDetails(false);
      console.log("Error fetching getInstrucDetails payload: ", error.message);
    }
  };

  useEffect(() => {
    const filterData = getCookie("filterData");
    console.log("filterData : ", filterData);
    if (filterData) {
      try {
        const { schoolId, gradeLevel } = JSON.parse(filterData);
        setVerifySchoolId(schoolId);
      } catch (error: any) {
        console.log("Failed to parse object : ", error.message);
      }
    }
  }, [schoolGradeValues, isCookieSet]);

  return (
    <>
      {/* <AcadamicUI schoolName={schoolName} gradeLevelData={gradeLevelData} /> */}
      {VerifySchoolId ? (
        <AcadamicUI schoolName={schoolName} gradeLevelData={gradeLevelData} />
      ) : (
        <div className="p-4 text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[25px] my-4 bg-white">
          <section className="flex justify-between">
            <p className="text-[20px] text-text_deep mb-2 font-medium ">
              Academics
            </p>
          </section>
          <hr />
          <div className="">
            <div className="h-full  ">
              <div className="bg-white rounded-[20px] p-3 ">
                <section className="flex justify-between gap-10">
                  <div className="flex flex-col gap-6 w-full">
                    <CourseIDs
                      onChange={(selected) =>
                        handleInputCourseChange("SET_COURSE_ID", selected)
                      }
                    />
                    <SchoolDs
                      onChange={(selected) =>
                        handleSchoolInputChange("SET_SCHOOL_ID", selected)
                      }
                    />
                    <GradeLevel
                      onChange={(selected) =>
                        handleInputGradeChange("SET_GRADE_ID", selected)
                      }
                    />
                    <NewDatePickerRange onDateData={onDateDataHandler} />

                    <AcademicIDs
                      onChange={(selected) =>
                        handleInputAcademicChange("SET_ACADEMIC_ID", selected)
                      }
                      schoolId={singleSchoolID}
                    />
                  </div>
                </section>

                <div className="flex justify-end mt-6">
                  <Button
                    onClick={handleSubmit}
                    className="rounded-[40px] py-2 px-10 text-[#6666EA] hover:text-[#6666EA]"
                    disabled={!state.isFormValid || isLoadingDetails}
                  >
                    {isLoadingDetails ? "Submitting..." : "Add Academic Year"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AcademicsTab;
