import { Col, Form, Row } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import CourseComponent from "./CourseComponent";

const Courses = () => {
  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar coursesActive="active" coursesIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Courses" />

          <Row className="mt-[10px]">
            {/* left side section */}
            <Col md={9} className="mt-[5px]">
              <div className="rounded-[20px] flex justify-between items-center mt-[15px]">
                <div>
                  <Form>
                    <Form.Group className="relative">
                      <Form.Control
                        className="h-[40px] w-[339px] rounded-[25px] shadow-none border-defaultBlue_40 focus:border-defaultBlue_40 pr-8"
                        placeholder="Search"
                      />
                      <MagnifyingGlassIcon
                        className="w-5 h-5 text-defaultBlue_40 absolute right-3 bottom-2.5"
                        type="submit"
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    className="shadow-none border-defaultBlue_40 focus:border-defaultBlue_40 h-[40px] w-[191px] rounded-[20px]"
                  >
                    <option value="paid">Newest</option>
                    <option value="unpaid">Oldest</option>
                  </Form.Select>
                </div>
              </div>

              {/* courses list */}
              <div className="mt-[15px]">
                <CourseComponent
                  image="https://pimages.toolbox.com/wp-content/uploads/2023/02/10044719/Picture-of-a-motherboard.jpg"
                  title="Basic Electronics"
                  unit={8}
                  tasks={6}
                  maxPoints={100}
                />
                <CourseComponent
                  image="https://pimages.toolbox.com/wp-content/uploads/2023/02/10044719/Picture-of-a-motherboard.jpg"
                  title="Basic Electronics"
                  unit={8}
                  tasks={6}
                  maxPoints={100}
                />
                <CourseComponent
                  image="https://pimages.toolbox.com/wp-content/uploads/2023/02/10044719/Picture-of-a-motherboard.jpg"
                  title="Basic Electronics"
                  unit={8}
                  tasks={6}
                  maxPoints={100}
                />
                <CourseComponent
                  image="https://pimages.toolbox.com/wp-content/uploads/2023/02/10044719/Picture-of-a-motherboard.jpg"
                  title="Basic Electronics"
                  unit={8}
                  tasks={6}
                  maxPoints={100}
                />
                <CourseComponent
                  image="https://pimages.toolbox.com/wp-content/uploads/2023/02/10044719/Picture-of-a-motherboard.jpg"
                  title="Basic Electronics"
                  unit={8}
                  tasks={6}
                  maxPoints={100}
                />
              </div>
            </Col>

            {/* right side section */}
            <Col md={3} className="mt-[5px]">
              <button className="w-[148px] h-[40px] rounded-[5px] bg-defaultBlue text-white">
                + Add Course
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Courses;
