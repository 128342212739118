import { Col, Row } from "react-bootstrap";
import { Typography } from "../../../../../components/typography/Typography";
import { MingoInput } from "../../../../../components/inputs/MingoInput";

const Attendance = () => {
  return (
    <div>
      <Row>
        <Col md={3}>
          <Typography.MediumText text="Display  Attendance Time period of" />
        </Col>
        <Col md={9}>
          <Row>
            <Col md={4}>
              <MingoInput.CheckBox label="Year" />
            </Col>
            <Col md={4}>
              <MingoInput.CheckBox label="Term" />
            </Col>
            <Col md={4}>
              <MingoInput.CheckBox label="Month" />
            </Col>
            <Col md={4} className="mt-2">
              <MingoInput.CheckBox label="Module" />
            </Col>
            <Col md={4} className="mt-2">
              <MingoInput.CheckBox label="Other" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={3}>
          <Typography.MediumText text="Display  Attendance status for" />
        </Col>
        <Col md={9}>
          <Row>
            <Col md={4}>
              <MingoInput.CheckBox label="Present" />
            </Col>
            <Col md={4}>
              <MingoInput.CheckBox label="Absent" />
            </Col>
            <Col md={4}>
              <MingoInput.CheckBox label="Late" />
            </Col>
            <Col md={4} className="mt-2">
              <MingoInput.CheckBox label="Health" />
            </Col>
            <Col md={4} className="mt-2">
              <MingoInput.CheckBox label="Excused" />
            </Col>
            <Col md={4} className="mt-2">
              <MingoInput.CheckBox label="Dismissed" />
            </Col>
            <Col md={4} className="mt-2">
              <MingoInput.CheckBox label="Field Trip" />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Attendance;
