import { Col, Image, Row } from "react-bootstrap";
import { Typography } from "../../../components/typography/Typography";
import { TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { MingoInput } from "../../../components/inputs/MingoInput";
import pdfIcon from "../../../images/pdfIcon.png";

const RecentReports = () => {
  return (
    <Row>
      <Col md={9}>
        <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[20px]">
          <Typography.MediumText text="Recent Reports" />

          <div className="mt-[20px] flex justify-between items-center mr-[100px] ml-5">
            <Typography.MediumText text="Title" />
            <Typography.MediumText text="Term" />
            <Typography.MediumText text="Date Created" />
            <Typography.MediumText text="Last Updated" />
          </div>

          <div className="mt-[10px]">
            {/* template component */}
            <TemplateComponent />
            <TemplateComponent />
            <TemplateComponent />
            <TemplateComponent />
            <TemplateComponent />
          </div>
        </div>
      </Col>
      <Col md={3}>
        <button className="rounded-[5px] text-white bg-defaultBlue h-[40px] w-auto px-3 flex justify-center items-center gap-[10px]">
          <UserPlusIcon className="w-5 h-5" />
          Add New Template
        </button>

        <MingoInput.OptionsSelect options={<option value="1">Term 1</option>} />
      </Col>
    </Row>
  );
};

// data mapper
const TemplateComponent = () => {
  return (
    <div className="px-[28px] py-[9px] mb-[10px] flex justify-between items-center border-[1px] border-defaultBlue_40 rounded-[20px] shadow-sm">
      <div className="flex gap-[10px] items-center border-r-[1.5px] border-defaultBlue_40 pr-3 ">
        <Image src={pdfIcon} alt="pdf" />
        <Typography.MediumText text="Report" xStyle="text-defaultBlue" />
      </div>
      <div className="flex flex-col justify-center items-center">
        <Typography.MediumText text="Term 1" />
        <Typography.SmallText
          text="Nov. 2022 to Sept 2023"
          xStyle="text-defaultBlue"
        />
      </div>
      <div className="border-x-[1.5px] border-defaultBlue_40 px-3">
        <Typography.MediumText text="34/03/2022 - 3:20 PM" />
      </div>
      <div className="border-r-[1.5px] border-defaultBlue_40 pr-5">
        <Typography.MediumText text="34/03/2022 - 3:20 PM" />
      </div>
      <div className="text-defaultBlue_40">
        <TrashIcon className="w-5 h-5 cursor-pointer" />
      </div>
    </div>
  );
};

export default RecentReports;
