import { Typography } from "../../../../components/typography/Typography";

const PerformanceCard = ({
  title,
  number,
  bottomPercentage,
  bottomText,
  icon,
}: any) => {
  return (
    <div className="rounded-[15px] px-[16px] py-[14px] bg-defaultWhite shadow-sm flex justify-between">
      <div className="text-defaultTex">
        <Typography.MediumText text={title} />

        <Typography.HeadingThree text={number} xStyle=" mt-[10px]" />

        <p className="text-[10px] mt-[10px]">
          <span className="text-defaultGreen">{bottomPercentage}</span>{" "}
          {bottomText}
        </p>
      </div>
      <div>{icon}</div>
    </div>
  );
};

export default PerformanceCard;
