import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AllTasksTab from "./AllTasksTab";
import CompletedTasksTab from "./CompletedTasksTab";
import { Typography } from "../../../../components/typography/Typography";

const TaskAndUnits = () => {
  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "All",
    },
    {
      name: "Completed",
    },
  ];

  //Tab active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === tabActive) {
      return "text-defaultText opacity-[70%] w-auto px-3 border-[2px] border-b-defaultBlueHover border-x-0 border-t-0";
    } else if (i === 1 && i === tabActive) {
      return "text-defaultText opacity-[70%] w-auto px-3 border-[2px] border-b-defaultBlueHover border-x-0 border-t-0";
    } else {
      return "text-defaultText opacity-[70%] w-auto px-3";
    }
  };

  return (
    <>
      <div className="bg-defaultWhite rounded-[20px] pt-[15px] pb-[30px] px-[20px] text-defaultText">
        <Typography.RegularText text="Tasks & Units" xStyle="font-semibold" />

        <div className="mt-[16px]">
          <Tabs>
            <TabList
              className="flex gap-[12px] mb-[20px] pb-2 overflow-x-auto"
              id="horizontalTab"
            >
              {tabNames.map((item, index) => (
                <Tab key={index} className="bg-transparent">
                  <button
                    onClick={setTabActive.bind(this, index)}
                    key={index}
                    className={showActive(index)}
                  >
                    <Typography.MediumText text={item.name} />
                  </button>
                </Tab>
              ))}
            </TabList>

            <div className="mb-[10px]"></div>

            <TabPanel>
              <AllTasksTab />
            </TabPanel>
            <TabPanel>
              <CompletedTasksTab />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default TaskAndUnits;
