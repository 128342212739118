import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import BaseService from '../helpers/baseServices';
import { global_variables } from '../helpers/globalVariables';
import { getCookie } from '../helpers/utils';


// Create the context
const GeneralContext = createContext<any>(null);

// Create a custom hook for easy access
export const useGeneralRequests = () => useContext(GeneralContext);

interface ApiProviderProps {
    children: ReactNode;
}

export const GeneralContextProvider: React.FC<ApiProviderProps> = ({ children }) => {



    //onload
    useEffect(() => {

    }, [])

    return (
        <GeneralContext.Provider value={{}}>
            {children}
        </GeneralContext.Provider>
    );
};
