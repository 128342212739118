import { Typography } from "../../../components/typography/Typography";
import { MingoInput } from "../../../components/inputs/MingoInput";

const Notifications = () => {
  return (
    <>
      <div className="h-[413px] rounded-[20px] bg-white shadow-sm text-defaultText">
        <div>
          <div className="py-[20px] mx-[30px]">
            <Typography.MediumText text="General" xStyle="font-semibold" />
          </div>

          <div className="h-[55px] w-full bg-defaultBlue_5 px-[30px] py-[5px] flex justify-between items-center">
            <div>
              <Typography.MediumText text="Popup Notification" />
              <Typography.SmallText text="Enable or disable push notifications on all mobile devices." />
            </div>

            <MingoInput.Switch />
          </div>
          <div className="h-[55px] w-full mt-[5px] bg-defaultBlue_5 px-[30px] py-[5px] flex justify-between items-center">
            <div>
              <Typography.MediumText text="Push Notification" />
              <Typography.SmallText text="Enable or disable push notifications on all mobile devices." />
            </div>

            <MingoInput.Switch />
          </div>
        </div>

        <div className="mt-[20px]">
          <div className="py-[20px] mx-[30px]">
            <Typography.MediumText text="Courses" xStyle="font-semibold" />
          </div>

          <div className="h-[55px] w-full bg-defaultBlue_5 px-[30px] py-[5px] flex justify-between items-center">
            <div>
              <Typography.MediumText text="Course Coupon Redeption " />
              <Typography.SmallText text="Enable or disable push notifications on all mobile devices." />
            </div>

            <MingoInput.Switch />
          </div>
          <div className="h-[55px] w-full mt-[5px] bg-defaultBlue_5 px-[30px] py-[5px] flex justify-between items-center">
            <div>
              <Typography.MediumText text="Complete Course" />
              <Typography.SmallText text="Enable or disable push notifications on all mobile devices." />
            </div>

            <MingoInput.Switch />
          </div>
        </div>
      </div>

      <div className="mt-[30px] flex justify-end items-center">
        <button className="text-white bg-defaultBlue w-[146px] h-[40px] rounded-[20px]">
          Save Changes
        </button>
      </div>
    </>
  );
};

export default Notifications;
