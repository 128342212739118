import { Image } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";

const ProfilePicture = () => {
  return (
    <div>
      <Image
        className="w-[133px] h-[133px] flex ml-auto mr-auto rounded-full object-cover"
        src="https://parade.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTkwNTc5NjU1ODUyNDM1MzI0/quotes-about-mothers-day-jpg.jpg"
        alt="..."
        fluid
      />

      <div className="text-center mt-[10px]">
        <Typography.MediumText
          text="Mawuli Togbor"
          xStyle="font-semibold text-defaultText"
        />
        <Typography.SmallText text="Student" xStyle="text-defaultText" />
      </div>
    </div>
  );
};

export default ProfilePicture;
