import { Col, Row } from "react-bootstrap";
import { Typography } from "../../../../../components/typography/Typography";
import { MingoInput } from "../../../../../components/inputs/MingoInput";
import NestedCheckbox from "../../../../../components/inputs/NestedCheckbox";

const ClassReports = () => {
  const nestedBoxOptions = [
    {
      label: "Tasks Grade",
      value: "Tasks Grade",
      options: [
        {
          label: "All Tasks",
          value: "All Tasks",
        },
        {
          label: "Quizes",
          value: "Quizes",
        },
        {
          label: "Assignments",
          value: "Assignments",
        },
        {
          label: "Research",
          value: "Research",
        },
      ],
    },
  ];

  const handleCheckboxChange = (checkedItems: any) => {
    // console.log(checkedItems);
  };

  return (
    <div>
      <Row className="flex items-center">
        <Col md={3}>
          <Typography.MediumText text="Report Title" />
        </Col>
        <Col md={9}>
          <MingoInput.OptionsSelect
            options={<option value="Class Report">Class Report</option>}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={3}>
          <Typography.MediumText text="Report Layout" />
        </Col>
        <Col md={9}>
          <div className="mb-3">
            <MingoInput.CheckBox label="Report Description" />
          </div>
          <div className="mb-3">
            <MingoInput.CheckBox label="Achievement Level" />
          </div>
          <div className="mb-3">
            <MingoInput.CheckBox label="Final Grade" />
          </div>
          <div className="mb-3">
            <NestedCheckbox
              options={nestedBoxOptions}
              onChange={handleCheckboxChange}
              checked={false}
            />
          </div>
          <div className="mb-3">
            <MingoInput.CheckBox label="Progress Chart" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ClassReports;
