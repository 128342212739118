import { Typography } from "../../../../components/typography/Typography";
import { OtherButtons } from "../../../../components/buttons/OtherButtons";

const LatestResultsCard = () => {
  return (
    <div className="border-0 shadow-sm rounded-[20px] px-[22px] pt-[22px] pb-[30px] bg-defaultWhite">
      <div className="flex justify-between">
        <Typography.MediumText
          text="Latest Results"
          xStyle="font-semibold w-[148px]"
        />
        <OtherButtons.SeeAll
          btnName="See all"
          onClick={() => alert("Ooouuu")}
        />
      </div>

      <div className="mt-[9px]">
        <DataLookLike
          title="Unit 1"
          percentage="25%"
          color="text-tertiary-pink"
        />
        <DataLookLike
          title="Unit 1"
          percentage="55%"
          color="text-defaultBlue"
        />
        <DataLookLike
          title="Unit 1"
          percentage="90%"
          color="text-secondary-green"
        />
        <DataLookLike
          title="Unit 1"
          percentage="90%"
          color="text-secondary-green"
        />
      </div>
    </div>
  );
};

interface dataProps {
  title: string;
  percentage: any;
  color: string;
}

// data look like
const DataLookLike = ({ title, percentage, color }: dataProps) => {
  return (
    <div className="flex items-center justify-between py-[14px] border-b border-defaultBlue_40">
      <div className="flex items-center gap-[5px] w-full">
        <Typography.SmallText text={title} />
        <span className="text-defaultBlue_60">-</span>
        <p className="text-[14px] text-defaultBlue_60 ml-[2px] w-[80px] truncate">
          Robotics Adventure
        </p>
        <div className="w-[80px]">
          <div className="bg-gray-200 h-[4px] rounded-[5px]">
            <div
              className="bg-defaultBlue h-[4px] rounded-[5px]"
              style={{ width: `${percentage}` }}
            ></div>
          </div>
        </div>
      </div>

      <Typography.MediumText
        text={percentage}
        xStyle={`font-semibold ${color}`}
      />
    </div>
  );
};

export default LatestResultsCard;
