import React from "react";
import { Typography } from "../../components/typography/Typography";
import { Dropdown, Image } from "react-bootstrap";
import {
  ChatBubbleLeftEllipsisIcon,
  EllipsisVerticalIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import DataTable from "react-data-table-component";

const MyClassTable = () => {
  //dummy data
  const dummyData = [
    {
      name: "John Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Early",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
    {
      name: "Prince Togbor",
      image: "https://xsgames.co/randomusers/assets/avatars/male/74.jpg",
      status: "Late",
    },
  ];
  //Table columns
  const columns = [
    {
      name: "",
      cell: (row: any) => (
        <div className="flex justify-between items-center mt-[10px] border-[1px] border-defaultBlue_40 w-full h-[50px] rounded-[20px] px-[15px]">
          <Typography.MediumText
            text={row.name}
            xStyle="text-defaultBlue font-semibold"
          />
          <div className="flex gap-[15px]">
            {/* images */}
            <div className="flex -space-x-1 overflow-hidden">
              <Image
                className="inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
                fluid
              />
              <Image
                className="inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
                fluid
              />
              <Image
                className="inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                alt=""
                fluid
              />
            </div>
            <div className="flex gap-[5px] items-center">
              <Typography.HeadingFour text="4" xStyle="text-secondary-green" />
              <Typography.MediumText text="Courses" />
            </div>
            <div className="flex gap-[5px] items-center">
              <Typography.HeadingFour text="6" xStyle="text-secondary-pink" />
              <Typography.MediumText text="Courses" />
            </div>
            <div className="flex gap-[5px] items-center">
              <Typography.HeadingFour text="4" xStyle="text-defaultYellow" />
              <Typography.MediumText text="Courses" />
            </div>
          </div>
          {/* <div className=" h-8"></div> */}
          <div className="flex gap-[15px] items-center text-defaultBlue border-[1.5px] pl-2 border-y-0 border-r-0 border-l-defaultBlue_40">
            <ChatBubbleLeftEllipsisIcon className="w-5 h-5 cursor-pointer" />
            <UserCircleIcon className="w-5 h-5 cursor-pointer" />
            <Dropdown className="flex items-center relative">
              <Dropdown.Toggle as={CustomToggle} />

              <Dropdown.Menu className="absolute right-0 bottom-0 z-10">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="mt-[30px] bg-white rounded-[20px] shadow-sm py-[20px] px-[30px] text-defaultText">
      <DataTable
        responsive
        progressComponent="Loading..."
        // progressPending={isFetching}
        data={dummyData}
        columns={columns}
        selectableRows
        onSelectedRowsChange={(dd) => console.log(dd)}
        paginationServer
        noDataComponent={
          <div>
            <p>No data to display</p>
          </div>
        }
      />
    </div>
  );
};

const CustomToggle = React.forwardRef((onClick: any, ref) => (
  <EllipsisVerticalIcon
    className="h-6 w-6 text-defaultBlue font-semibold cursor-pointer"
    onClick={onClick}
  />
));

export default MyClassTable;
