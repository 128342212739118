import { Typography } from "../../../components/typography/Typography";
import { Col, Row } from "react-bootstrap";
import { MingoInput } from "../../../components/inputs/MingoInput";

const SettingsTab = () => {
  return (
    <>
      {/* General */}
      <div className="w-full h-auto pb-[30px] rounded-[20px] shadow-sm bg-white text-defaultText pt-[20px] px-[30px]">
        <Typography.RegularText text="Quiz Settings" />

        <hr className="my-[15px]" />

        <div>
          <Typography.MediumText text="General" />

          <Row>
            <Col className="mt-3" md={3}>
              <Typography.MediumText text="Quiz Title" />
            </Col>
            <Col className="mt-3" md={9}>
              <MingoInput.Cornered type="text" placeholder="Title" />
            </Col>
          </Row>

          <Row>
            <Col className="mt-3" md={3}>
              <Typography.MediumText text="Description" />
            </Col>
            <Col className="mt-3" md={9}>
              <MingoInput.InputWithTools />
              <div className="mt-3">
                <MingoInput.CheckBox label="Display description on course page" />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-4" md={3}>
              <Typography.MediumText text="Upload File" />
            </Col>
            <Col className="mt-4" md={9}>
              <MingoInput.ImageSelect />
            </Col>
          </Row>

          <Row>
            <Col className="mt-4" md={3}>
              <Typography.MediumText text="Shaffle Questions" />
            </Col>
            <Col className="mt-4 flex items-center" md={9}>
              <MingoInput.OptionsSelect
                options={
                  <>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </>
                }
              />
            </Col>
          </Row>
        </div>
      </div>

      {/* timing */}
      <div className="mt-4 w-full h-auto pb-[30px] rounded-[20px] shadow-sm bg-white text-defaultText pt-[20px] px-[30px]">
        <div>
          <Typography.MediumText text="Timing" />

          <Row>
            <Col className="mt-3" md={3}>
              <Typography.MediumText text="Open Date" />
            </Col>
            <Col className="mt-3" md={9}>
              <div className="flex justify-between items-center gap-3">
                <MingoInput.Cornered type="date" />
                <MingoInput.Cornered type="time" />
                <MingoInput.CheckBox label="Enable" />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-3" md={3}>
              <Typography.MediumText text="End Date" />
            </Col>
            <Col className="mt-3" md={9}>
              <div className="flex justify-between items-center gap-3">
                <MingoInput.Cornered type="date" />
                <MingoInput.Cornered type="time" />
                <MingoInput.CheckBox label="Enable" />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-3" md={3}>
              <Typography.MediumText text="Time Late" />
            </Col>
            <Col className="mt-3" md={9}>
              <div className="flex justify-between items-center gap-3">
                <MingoInput.Cornered type="text" />
                <MingoInput.OptionsSelect
                  options={
                    <>
                      <option value="Minute">Minute(s)</option>
                      <option value="Hour">Hour</option>
                    </>
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-1 flex items-center" md={3}>
              <Typography.MediumText text="After Timeout" />
            </Col>
            <Col className="mt-1" md={9}>
              <MingoInput.OptionsSelect
                options={
                  <>
                    <option value="auto">Submitted Quiz Automatically</option>
                    <option value="manual">Submitted Quiz Manually</option>
                  </>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col className="mt-1 flex items-center" md={3}>
              <Typography.MediumText text="Allow Attempts" />
            </Col>
            <Col className="mt-1" md={9}>
              <MingoInput.OptionsSelect
                options={
                  <>
                    <option value="auto">Unlimited</option>
                    <option value="manual">Something</option>
                  </>
                }
              />
            </Col>
          </Row>
        </div>
      </div>

      {/* grading */}
      <div className="mt-4 w-full h-auto pb-[30px] rounded-[20px] shadow-sm bg-white text-defaultText pt-[20px] px-[30px]">
        <div>
          <Typography.MediumText text="Grading" />

          <Row>
            <Col className="mt-1 flex items-center" md={3}>
              <Typography.MediumText text="Maximum Grade/point" />
            </Col>
            <Col className="mt-1" md={9}>
              <MingoInput.Cornered type="text" />
            </Col>
          </Row>

          <Row>
            <Col className="mt-1 flex items-center" md={3}>
              <Typography.MediumText text="Pass Grade" />
            </Col>
            <Col className="mt-1" md={9}>
              <MingoInput.OptionsSelect
                options={
                  <>
                    <option value="100">100</option>
                  </>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col className="mt-1 flex items-center" md={3}>
              <Typography.MediumText text="Grade per Question" />
            </Col>
            <Col className="mt-1" md={9}>
              <MingoInput.OptionsSelect
                options={
                  <>
                    <option value="1">1</option>
                    <option value="5">5</option>
                  </>
                }
              />
            </Col>
          </Row>
        </div>
      </div>

      {/* review */}
      <div className="mt-4 w-full h-auto pb-[30px] rounded-[20px] shadow-sm bg-white text-defaultText pt-[20px] px-[30px]">
        <div>
          <Typography.MediumText text="Review" />

          <Row>
            <Col className="mt-1" md={3}>
              <Typography.MediumText text="Show Review" />
            </Col>
            <Col className="mt-1" md={9}>
              <Row>
                <Col md={6}>
                  <Typography.MediumText text="Immediately after the attempt" />
                  <div className="mt-2">
                    <MingoInput.CheckBox label="Whether correct" />
                    <MingoInput.CheckBox label="Marks" />
                    <MingoInput.CheckBox label="Specific feedback" />
                    <MingoInput.CheckBox label="Right answer" />
                  </div>
                </Col>
                <Col md={6}>
                  <Typography.MediumText text="After Quiz is submitted" />
                  <div className="mt-2">
                    <MingoInput.CheckBox label="Whether correct" />
                    <MingoInput.CheckBox label="Marks" />
                    <MingoInput.CheckBox label="Specific feedback" />
                    <MingoInput.CheckBox label="Right answer" />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SettingsTab;
