import { useState } from "react";
import { Typography } from "../../components/typography/Typography";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Col, Row } from "react-bootstrap";
import EditProfileModal from "./EditProfileModal";

const PersonalDetails = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="bg-defaultWhite shadow-sm pb-[40px] mb-[10px] w-full rounded-[20px] px-[30px] py-[25px] text-defaultText">
        <div className="flex justify-between items-center">
          <Typography.MediumText
            text="Personal Details"
            xStyle="font-semibold"
          />
          <button
            className="flex gap-[5px] items-center font-semibold text-defaultBlue"
            onClick={() => handleShow()}
          >
            <PencilSquareIcon className="w-4 h-4" />
            <Typography.MediumText text="Edit" />
          </button>
        </div>

        <hr className="mt-2" />

        <Row>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="First Name" />
            <Typography.MediumText text="Vivian" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Prefered Name" />
            <Typography.MediumText text="Lorem Ipsum" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Contact 1" />
            <Typography.MediumText text="+233000221456" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Last Name" />
            <Typography.MediumText text="Togbor" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Date of Birth" />
            <Typography.MediumText text="28/05/2016" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Parent ID" />
            <Typography.MediumText text="RCS848480289" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Middle Name" />
            <Typography.MediumText text="Abena" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Email" />
            <Typography.MediumText
              text="lorem@dolor.com"
              xStyle="text-defaultBlue"
            />
          </Col>
        </Row>
      </div>

      <EditProfileModal showModal={show} handleModalClose={handleClose} />
    </>
  );
};

export default PersonalDetails;
