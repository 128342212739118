import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";

interface theProps {
  onChange: (selected: string) => void;
  gender?: string;
}

const SelectGender = ({ onChange, gender }: theProps) => {
  const [selectedGender, setSelectedGender] = useState<string | undefined>(
    gender
  );

  const handleChange = useCallback(
    (value: string) => {
      setSelectedGender(value);
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (gender) {
      setSelectedGender(gender);
    }
  }, [gender]);

  return (
    <div>
      <label className="block text-[#0F0F37] mb-1">Gender</label>
      <Select
        id="category"
        value={selectedGender}
        className="h-[48px] w-full rounded-[10px]"
        options={[
          { value: "M", label: "Male" },
          { value: "F", label: "Female" },
          { value: "O", label: "Other" },
        ]}
        onChange={handleChange}
        placeholder="Select Gender"
        allowClear
      />
    </div>
  );
};

export default SelectGender;
