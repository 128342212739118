import { useState } from "react";
import { Image } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import browseIcon from "../../../images/browseIcon.png";
import overviewIcon from "../../../images/overviewIcon.png";
import taskIcon from "../../../images/taskIcon.png";
import noteIcon from "../../../images/noteIcon.png";
import messageIcon from "../../../images/messageIcon.png";
import gradeIcon from "../../../images/gradeIcon.png";
import forumIcon from "../../../images/forumIcon.png";
import QuickBrowse from "./Quick_Browse/QuickBrowse";
import Overview from "./Overview/Overview";
import TaskAndUnits from "./Tasks_Units/TaskAndUnits";
import Notes from "./Notes/Notes";
import Messages from "./Message/Messages";
import Grades from "./Grades/Grades";
import Forum from "./Forums/Forum";
import Navbar from "../../../components/navbar/Navbar";
import ProSideBar from "../../../components/sidebar/pro-sidebar";
import { Typography } from "../../../components/typography/Typography";
import { OtherButtons } from "../../../components/buttons/OtherButtons";
import { useNavigate } from "react-router-dom";

const Classroom = () => {
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "Quick Browse",
      icon: <Image className="" src={browseIcon} alt="..." fluid />,
    },
    {
      name: "Overview",
      icon: <Image className="" src={overviewIcon} alt="..." fluid />,
    },
    {
      name: "Task & Units",
      icon: <Image className="" src={taskIcon} alt="..." fluid />,
    },
    {
      name: "All Notes",
      icon: <Image className="" src={noteIcon} alt="..." fluid />,
    },
    {
      name: "Messages",
      icon: <Image className="" src={messageIcon} alt="..." fluid />,
    },
    {
      name: "Grade",
      icon: <Image className="" src={gradeIcon} alt="..." fluid />,
    },
    {
      name: "Forum",
      icon: <Image className="" src={forumIcon} alt="..." fluid />,
    },
  ];

  //Tab active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === tabActive) {
      return "w-[180px] h-[56px] flex items-center gap-[14px] pl-[10px] bg-gradient-to-r from-gradient-blueEnd to-gradient-blueStart text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 1 && i === tabActive) {
      return "w-[180px] h-[56px] flex items-center gap-[14px] pl-[10px] bg-gradient-to-r from-gradient-violetStart to-gradient-violetEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 2 && i === tabActive) {
      return "w-[180px] h-[56px] flex items-center gap-[14px] pl-[10px] bg-gradient-to-r from-gradient-yellowStart to-gradient-yellowEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 3 && i === tabActive) {
      return "w-[180px] h-[56px] flex items-center gap-[14px] pl-[10px] bg-gradient-to-r from-gradient-lightGreenEnd to-gradient-lightGreenStart text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 4 && i === tabActive) {
      return "w-[180px] h-[56px] flex items-center gap-[14px] pl-[10px] bg-gradient-to-r from-gradient-orangeStart to-gradient-orangeEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 5 && i === tabActive) {
      return "w-[180px] h-[56px] flex items-center gap-[14px] pl-[10px] bg-gradient-to-r from-gradient-deepPinkStart to-gradient-deepPinkEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 6 && i === tabActive) {
      return "w-[180px] h-[56px] flex items-center gap-[14px] pl-[10px] bg-gradient-to-r from-gradient-lightBlueEnd to-gradient-lightBlueStart text-defaultWhite shadow-sm rounded-[20px]";
    } else {
      return "w-[180px] h-[56px] flex items-center gap-[14px] pl-[10px] bg-defaultWhite shadow-sm rounded-[20px]";
    }
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar courseGroupActive="active" courseGroupIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto overflow-x-hidden">
          <div className="flex gap-[20px] items-center">
            <OtherButtons.BackButton onClick={() => navigate("/year_group")} />
            <Typography.HeadingThree text="Grade 4 Gold" />
          </div>

          <Tabs className="mt-[20px]">
            <TabList
              className="flex gap-[12px] mb-[20px] pb-2 overflow-x-auto"
              id="horizontalTab"
            >
              {tabNames.map((item, index) => (
                <Tab key={index} className="bg-transparent">
                  <button
                    onClick={setTabActive.bind(this, index)}
                    key={index}
                    className={showActive(index)}
                  >
                    {item.icon}
                    <Typography.MediumText text={item.name} />
                  </button>
                </Tab>
              ))}
            </TabList>

            <div className="mb-[10px]"></div>

            <TabPanel>
              <QuickBrowse />
            </TabPanel>
            <TabPanel>
              <Overview />
            </TabPanel>
            <TabPanel>
              <TaskAndUnits />
            </TabPanel>
            <TabPanel>
              <Notes />
            </TabPanel>
            <TabPanel>
              <Messages />
            </TabPanel>
            <TabPanel>
              <Grades />
            </TabPanel>
            <TabPanel>
              <Forum />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Classroom;
