import { useState, useEffect } from "react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import imgUploadIcon from "../../../images/imgUploadIcon.png";
import { Image } from "react-bootstrap";

const ImgUploadComponent = () => {
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (file) {
      const reader: any = new FileReader();

      reader.addEventListener("load", () => {
        setImagePreview(reader.result);
      });

      reader.readAsDataURL(file);
    }
  }, [file]);

  const handleFileInputChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  return (
    <label
      htmlFor="image-upload"
      className="mt-[20px] h-[203px] cursor-pointer flex justify-center items-center bg-defaultBlue_5 border-[2px] border-dashed border-defaultBlue_40 rounded-[5px]"
    >
      {imagePreview ? (
        <Image
          className="w-full h-[198px] object-cover rounded-[5px]"
          src={imagePreview}
          alt="Preview"
        />
      ) : (
        <div>
          <Image
            className="flex mr-auto ml-auto"
            src={imgUploadIcon}
            alt="..."
          />
          <div className="mt-[20px] flex items-center">
            <ArrowUpTrayIcon className="w-5 h-5 text-defaultBlue mr-3" />
            <span className="text-defaultText">Drag and drop</span>
            <span className="text-defaultBlue ml-1">Upload image</span>
          </div>
        </div>
      )}
      <input
        id="image-upload"
        type="file"
        name="image"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
    </label>
  );
};

export default ImgUploadComponent;
