import { DatePicker } from "antd";
import React from "react";
import { Dayjs } from "dayjs";

const DatePickerField = ({ onDateData }: { onDateData: any }) => {
  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const startOfDay = date.startOf("day").toISOString();
      // const endOfDay = date.endOf("day").toISOString();
      onDateData({ start: startOfDay });
    } else {
      onDateData({ start: null });
    }
  };

  return (
    <div>
      <label className="block text-[#0F0F37] mb-1">DOB</label>
      <DatePicker
        placeholder="Select Date of Birth"
        className="w-full h-[48px] rounded-[10px] border"
        onChange={(date) => handleDateChange(date)}
        format="YYYY-MM-DD"
      />
    </div>
  );
};

export default DatePickerField;
