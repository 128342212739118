interface withoutIconProps {
  onClick?: () => void;
  btnName?: string;
  xStyle?: any;
}
interface withIconProps {
  onClick?: () => void;
  btnName?: string;
  icon?: any;
}

const Filled = ({ onClick, btnName }: withoutIconProps) => {
  return (
    <button
      className="bg-defaultBlue text-[16px] text-defaultWhite hover:bg-defaultBlueHover pt-[9px] px-[55px] pb-[9px] w-auto h-[40px] rounded-[22px]"
      onClick={onClick}
    >
      {btnName}
    </button>
  );
};

const FilledWithIcon = ({ btnName, icon, onClick }: withIconProps) => {
  return (
    <button
      className="bg-defaultBlue text-[16px] text-defaultWhite hover:bg-defaultBlueHover pt-[8px] px-[26px] pb-[8px] w-auto h-[41px] rounded-[22px]"
      onClick={onClick}
    >
      <div className="flex gap-1 items-center">
        {icon}
        {btnName}
      </div>
    </button>
  );
};

const Outlined = ({ btnName, onClick, xStyle }: withoutIconProps) => {
  return (
    <button
      className={`outline outline-1 text-[16px] outline-defaultBlue text-defaultBlue hover:outline-2 hover:outline-defaultBlueHover hover:text-defaultBlueHover pt-[9px] px-[55px] pb-[9px] h-[40px] rounded-[22px] ${xStyle}`}
      onClick={onClick}
      id="outlineBtn"
    >
      {btnName}
    </button>
  );
};

const OutlinedWithIcon = ({ btnName, icon, onClick }: withIconProps) => {
  return (
    <button
      className="outline outline-1 text-[16px] outline-defaultBlue text-defaultBlue hover:outline-2 hover:outline-defaultBlueHover hover:text-defaultBlueHover pt-[9px] px-[55px] pb-[9px] w-auto h-[40px] rounded-[22px]"
      onClick={onClick}
    >
      <div className="flex gap-1 items-center">
        {icon}
        {btnName}
      </div>
    </button>
  );
};

const WhiteBackground = ({ btnName, onClick, icon }: any) => {
  return (
    <button
      onClick={onClick}
      className="text-defaultBlue bg-white shadow-sm border-[1.5px] border-defaultBlue_40 flex gap-2 items-center h-[40px] rounded-[5px] w-full px-3 hover:border-defaultBlue"
    >
      {icon}
      {btnName}
    </button>
  );
};

export const NormalButtons = {
  Filled,
  Outlined,
  FilledWithIcon,
  OutlinedWithIcon,
  WhiteBackground,
};
