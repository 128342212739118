import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import StudentsTable from "./StudentsTable";
import InstructorsTable from "./InstructorsTable";

const SchoolDirectory = () => {
  const [tabActive, setTabActive] = useState(0);

  const tabs = [{ name: "Students" }, { name: "Instructors" }];
  return (
    <div className="pb-[40px] rounded-[20px] bg-white px-[30px] py-[20px] shadow-sm text-defaultText">
      <Tabs>
        <TabList className="flex gap-[20px] mb-[20px]">
          {tabs.map((_d, i) => (
            <Tab
              key={i}
              className={
                tabActive === i
                  ? `border-b-[2px] border-defaultBlue cursor-pointer px-2`
                  : `cursor-pointer px-2`
              }
              onClick={() => setTabActive(i)}
            >
              {_d.name}
            </Tab>
          ))}
        </TabList>
        <TabPanel>
          <StudentsTable />
        </TabPanel>
        <TabPanel>
          <InstructorsTable />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default SchoolDirectory;
