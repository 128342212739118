import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SchoolInfo from "./SchoolInfo";
import AcademicsTab from "./AcademicsTab/AcademicsTabAca";
import Terminologies from "./Terminologies";
import SchoolDirectory from "./school_directory/SchoolDirectory";

const SchoolNotify = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { name: "School Info" },
    { name: "Academics" },
    { name: "Terminologies" },
    { name: "School Directory" },
  ];

  return (
    <>
      <Tabs className="mt-[20px]">
        <TabList className="flex gap-[20px] mb-[20px]">
          {tabs.map((_d, i) => (
            <Tab
              key={i}
              className={
                activeTab === i
                  ? `border-b-[2px] border-defaultBlue cursor-pointer px-2 bg-transparent`
                  : `cursor-pointer px-2`
              }
              onClick={() => setActiveTab(i)}
            >
              {_d.name}
            </Tab>
          ))}
        </TabList>
        <TabPanel>
          <SchoolInfo />
        </TabPanel>
        <TabPanel>
          <AcademicsTab />
        </TabPanel>
        <TabPanel>
          <Terminologies />
        </TabPanel>
        <TabPanel>
          <SchoolDirectory />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default SchoolNotify;
