import { Typography } from "../../components/typography/Typography";
import { OtherButtons } from "../../components/buttons/OtherButtons";
import { useNavigate } from "react-router-dom";
import { MingoInput } from "../../components/inputs/MingoInput";

const PreviewQuiz = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row overflow-x-hidden h-[100vh] w-full overflow-y-scroll">
      {/* main content */}
      <div className="container pt-[30px] pb-[58px] px-[50px] h-full">
        <div className="flex items-center gap-[20px]">
          <OtherButtons.BackButton onClick={() => navigate("/add_quiz")} />
          <Typography.HeadingThree text="Preview Quiz" />
        </div>

        <div className="mt-[30px] mx-[50px]">
          {/* top card */}
          <div className="w-full bg-defaultBlue text-white rounded-[20px] shadow-sm py-[27px] px-[30px]">
            <Typography.RegularText text="Advanced Robotics" />
            <div className="flex items-center gap-[20px] mt-[10px]">
              <Typography.SmallText
                text="Graded Quiz"
                xStyle="bg-secondary-orange text-defaultWhite w-fit px-2 y-1 rounded-[50px]"
              />
              <Typography.SmallText text="40 mins" />
              <Typography.SmallText text="Total Points - 20" />
            </div>
          </div>

          {/* quetions card */}
          <QuestionComponent />
          <QuestionComponent />
          <br />
        </div>
      </div>
    </div>
  );
};

const QuestionComponent = () => {
  return (
    <div className="bg-white shadow-sm text-defaultText border-[1px] border-defaultBlue_40 rounded-[20px] mt-[10px] px-[30px] py-[30px]">
      <Typography.RegularText text="Q1." xStyle="font-semibold" />
      <Typography.MediumText
        text="Bawds jog, flick quartz, vex nymphs. The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs."
        xStyle="mt-2"
      />

      {/* answers */}
      <div className="mt-3">
        <div className="mt-2">
          <MingoInput.RadioCheck label="Lorem ipsum" />
        </div>
        <div className="mt-2">
          <MingoInput.RadioCheck label="Lorem ipsum" />
        </div>
        <div className="mt-2">
          <MingoInput.RadioCheck label="Lorem ipsum" />
        </div>
        <div className="mt-2">
          <MingoInput.RadioCheck label="Lorem ipsum" />
        </div>
      </div>
    </div>
  );
};

export default PreviewQuiz;
