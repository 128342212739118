import { Typography } from "../../../components/typography/Typography";
import { Col, Row } from "react-bootstrap";
import { MingoInput } from "../../../components/inputs/MingoInput";

const Terminologies = () => {
  return (
    <>
      <div className="bg-defaultWhite  w-full rounded-[20px] px-[30px] py-[25px] shadow-sm text-defaultText">
        <div className="flex justify-between items-center">
          <Typography.MediumText text="Terminologies" xStyle="font-semibold" />
        </div>

        <hr className="mt-2" />

        <Row className="mt-[15px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Grade title" />
          </Col>
          <Col md={8}>
            <MingoInput.Cornered type="text" placeholder="Type here..." />
          </Col>
        </Row>
        <Row className="mt-[15px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Instructor title" />
          </Col>
          <Col md={8}>
            <MingoInput.Cornered type="text" placeholder="Type here..." />
          </Col>
        </Row>
        <Row className="mt-[15px]">
          <Col md={4} className="flex items-center">
            <Typography.MediumText text="Timetable Period title" />
          </Col>
          <Col md={8}>
            <MingoInput.Cornered type="text" placeholder="Type here..." />
          </Col>
        </Row>

        <div className="mt-[30px] flex justify-end">
          <button className="h-[40px] w-[146px] rounded-[20px] text-white bg-secondary-green">
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default Terminologies;
