import React, { useEffect, useReducer, useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Button, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import BaseService from "../../../../helpers/baseServices";
import CourseIDs from "../../../Calender/FormElements/CourseIDs";
import SchoolDs from "../../../Calender/FormElements/SchoolDs";
import GradeLevel from "../../../Calender/FormElements/GradeLevel";
import AcademicIDs from "../../../Calender/FormElements/AcademicIDs";
import EditDateRange from "../../../Calender/FormElements/EditDateRange";
import { getCookie } from "../../../../helpers/utils";
interface CreateInterestProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

type Action =
  | { type: "SET_COURSE_ID"; payload: string }
  | { type: "SET_ACADEMIC_ID"; payload: string }
  | { type: "SET_SCHOOL_ID"; payload: string }
  | { type: "SET_GRADE_ID"; payload: string }
  | { type: "SET_MEETING_ID"; payload: any }
  | { type: "SET_FORM_VALIDITY"; payload: boolean }
  | { type: "GET_PREVIOUS_DATA"; payload: any };

const initialState = {
  courseId: "",
  acadermictermId: "",
  schoolId: "",
  gradeLevel: "",
  meetings: [],
  isFormValid: false,
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case "SET_COURSE_ID":
      return { ...state, courseId: action.payload };
    case "SET_ACADEMIC_ID":
      return { ...state, acadermictermId: action.payload };
    case "SET_SCHOOL_ID":
      return { ...state, schoolId: action.payload };
    case "SET_GRADE_ID":
      return { ...state, gradeLevel: action.payload };
    case "SET_MEETING_ID":
      return { ...state, meetings: action.payload };
    case "GET_PREVIOUS_DATA":
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

const EditAcadamic = ({
  handleReFetch,
  showModal,
  open,
  setOpen,
}: CreateInterestProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [singleSchoolID, setSingleSchoolID] = useState<any>();
  const [isLoadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [isAcadamciDataLoading, setAcadamciDataLoading] =
    useState<boolean>(false);
  const [SchoolId, setSchoolId] = useState("");
  const [GradeLevl, setGradeLevel] = useState("");

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    const filterData = getCookie("filterData");
    console.log("filterData : ", filterData);
    if (filterData) {
      try {
        const { schoolId, gradeLevel } = JSON.parse(filterData);
        setSchoolId(schoolId);
        setGradeLevel(gradeLevel);
      } catch (error: any) {
        console.log("Failed to parse object : ", error.message);
      }
    }
  }, []);

  const getSingleAcadamicData = async () => {
    if (!SchoolId || !GradeLevl) {
      console.log("Error: Missing school or grade level information.");
      return;
    }

    setAcadamciDataLoading(true);
    try {
      const response = await BaseService.get_api(
        `/robocentre/event-faqs/${SchoolId}/${GradeLevl}`
      );
      const { payload } = await response.data;
      dispatch({ type: "GET_PREVIOUS_DATA", payload: payload });
      console.log("get a single value from update : ", payload);
    } catch (error: any) {
      console.log("Error fetching payload: ", error.message);
    } finally {
      setAcadamciDataLoading(false);
    }
  };

  useEffect(() => {
    if (!SchoolId || !GradeLevl) return;
    getSingleAcadamicData();
  }, [SchoolId, GradeLevl]);

  const handleInputCourseChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };
  const handleSchoolInputChange = (type: Action["type"], value: any) => {
    setSingleSchoolID(value);
    dispatch({ type, payload: value });
  };
  const handleInputGradeChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };
  const handleInputAcademicChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };
  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    dispatch({ type: "SET_MEETING_ID", payload: [{ start, end }] });
  };

  const handleSubmit = async () => {
    setLoadingDetails(true);
    try {
      const data = {
        courseId: state.courseId,
        acadermictermId: state.acadermictermId,
        schoolId: state.schoolId,
        gradeLevel: state.gradeLevel,
        meetings: state.meetings,
      };
      const response = await BaseService.put_api(
        `/robocentre/roboinstructor/setup/calender`,
        data
      );
      const { payload } = await response.data;

      console.log("payload : ", payload);
    } catch (error: any) {
      console.log("Error fetching getInstrucDetails payload: ", error.message);
    } finally {
      setLoadingDetails(false);
    }
  };

  return (
    <div>
      <Button
        type="default"
        className="flex items-center rounded-[20px]"
        onClick={showModal}
      >
        <div className="flex items-center justify-center">
          <p>
            <PencilIcon className="w-5  h-5" />
          </p>
          <p> Edit Academic</p>
        </div>
      </Button>
      <Modal
        width={"50%"}
        open={open}
        footer={null}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <div className="p-4 text-text_deep  rounded-[25px] my-4 bg-white">
          <Spin
            spinning={isAcadamciDataLoading}
            indicator={
              <LoadingOutlined
                style={{ fontSize: 64, color: "#6666EA" }}
                spin
              />
            }
          >
            <section className="flex justify-between">
              <p className="text-[20px] text-text_deep mb-2 font-medium ">
                Edit Academics
              </p>
            </section>
            <hr />
            <div className="">
              <div className="h-full  ">
                <div className="bg-white rounded-[20px] p-3 ">
                  <section className="flex justify-between gap-10">
                    <div className="flex flex-col gap-6 w-full">
                      <CourseIDs
                        courseId={state.courseId}
                        onChange={(selected) =>
                          handleInputCourseChange("SET_COURSE_ID", selected)
                        }
                      />
                      <SchoolDs
                        schoolId={state.schoolId}
                        onChange={(selected) =>
                          handleSchoolInputChange("SET_SCHOOL_ID", selected)
                        }
                      />
                      <GradeLevel
                        gradeLevel={state.gradeLevel}
                        onChange={(selected) =>
                          handleInputGradeChange("SET_GRADE_ID", selected)
                        }
                      />
                      <EditDateRange
                        meetingsDate={state.meetings[0]}
                        onDateData={onDateDataHandler}
                      />

                      <AcademicIDs
                        onChange={(selected) =>
                          handleInputAcademicChange("SET_ACADEMIC_ID", selected)
                        }
                        schoolId={singleSchoolID}
                      />
                    </div>
                  </section>

                  <div className="flex justify-end mt-6">
                    <Button
                      onClick={handleSubmit}
                      className="rounded-[40px] py-2 px-10 text-[#6666EA] hover:text-[#6666EA]"
                      disabled={isLoadingDetails}
                    >
                      {isLoadingDetails
                        ? "Submitting..."
                        : "Update Academic Year"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

export default EditAcadamic;
