import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import All from "./All";
import Current from "./Current";
import Completed from "./Completed";

const Courses = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabList = ["All", "Current", "Completed"];

  return (
    <div>
      <Tabs>
        <TabList className="flex">
          {tabList.map((_d, i) => (
            <Tab
              className={`${
                activeTab === i
                  ? "border-0 bg-white cursor-pointer px-3 py-1 rounded-t-[10px]"
                  : "border-0 bg-[#E0E0FB] cursor-pointer px-3 py-1 rounded-t-[10px]"
              }`}
              key={i}
              onClick={() => setActiveTab(i)}
            >
              {_d}
            </Tab>
          ))}
        </TabList>
        <TabPanel>
          <div className="w-full rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
            <All />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="w-full rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
            <Current />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="w-full rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
            <Completed />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Courses;
