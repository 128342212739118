import { useEffect, useState } from "react";
import { MingoInput } from "./MingoInput";

interface Option {
  value: string;
  label?: string;
  options?: Option[];
}

interface NestedCheckboxProps {
  options: Option[];
  onChange: (checkedItems: Record<string, boolean>) => void;
  checked: boolean;
  label?: string;
}

const NestedCheckbox = ({
  options,
  onChange,
  checked: parentChecked,
  label,
}: NestedCheckboxProps) => {
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [checked, setChecked] = useState<boolean>(parentChecked);

  useEffect(() => {
    // Update the checked state and checkedItems state when the parentChecked prop changes
    setChecked(parentChecked);
    const newCheckedItems = options.reduce<Record<string, boolean>>(
      (acc, option) => {
        acc[option.value] = parentChecked;
        return acc;
      },
      {}
    );
    setCheckedItems(newCheckedItems);
  }, [options, parentChecked]);

  const handleChange = (value: string, isChecked: boolean) => {
    const newCheckedItems = { ...checkedItems, [value]: isChecked };
    setCheckedItems(newCheckedItems);
    onChange(newCheckedItems);

    // Check if all nested boxes are checked
    const allNestedChecked = options.every((option) => {
      if (option.options) {
        return option.options.every(
          (subOption) => newCheckedItems[subOption.value]
        );
      } else {
        return newCheckedItems[option.value];
      }
    });

    // Update parent checkbox state
    setChecked(allNestedChecked);
  };

  const renderCheckbox = (option: Option) => {
    const isChecked = checkedItems[option.value] || false;

    return (
      <div className="mb-2" key={option.value}>
        <MingoInput.CheckBox
          key={option.value}
          label={option.value}
          value={option.value}
          onChange={(event: any) =>
            handleChange(option.value, event.target.checked)
          }
          checked={isChecked}
        />
      </div>
    );
  };

  const renderSubCheckbox = (option: Option) => {
    const isChecked = checkedItems[option.value] || false;

    return (
      <div key={option.value} className="mb-2">
        <MingoInput.CheckBox
          key={option.value}
          label={option.value}
          value={option.value}
          onChange={(event: any) =>
            handleChange(option.value, event.target.checked)
          }
          checked={isChecked}
        />
        {option.options && (
          <NestedCheckbox
            label={option.label}
            options={option.options}
            onChange={(newCheckedItems) =>
              handleChange(option.value, newCheckedItems[option.value])
            }
            checked={isChecked} // Pass down the checked state to the child components
          />
        )}
      </div>
    );
  };

  return (
    <div className="ml-5">
      {options.map((option) =>
        option.options ? renderSubCheckbox(option) : renderCheckbox(option)
      )}
    </div>
  );
};

export default NestedCheckbox;
