import { useEffect, useReducer, useState } from "react";
import { Checkbox, Progress } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import OnBoardFormData from "./OnBoardFormData";

const OnBoard = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [RefreshData, setRefreshData] = useState<boolean>(false);

  const tabNames = ["Professional Information"];
  const Tabs = [<OnBoardFormData image={""} />, <OnBoardFormData image={""} />];

  return (
    <div className="w-full bg-lightpink h-screen max-w-[1600px] mx-auto overflow-y-auto p-5 font-baloo">
      <p className="text-[42px] z-[200] indent-20 mt-[50px] font-[600] text-[#252363] ">
        Get Started
      </p>

      <section className="w-full flex items-center justify-center gap-10">
        <div className="mt-[30px] w-[90%] flex gap-10">
          <div className="flex sticky top-0 h-[300px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] w-[300px] flex-col">
            <section className="py-4 bg-white rounded-[10px]">
              {tabNames.map((d, i) => (
                <div
                  key={i}
                  className={`${
                    activeTab === i
                      ? "bg-[#F9F9FF] font-[600] border-l-4 border-l-[#6666EA]"
                      : "text-[#252363]"
                  } text-[#252363] bg-white cursor-pointer`}
                  onClick={() => setActiveTab(i)}
                >
                  <div className="gap-4 flex px-6 py-4">
                    <Checkbox
                      checked={activeTab === i}
                      className="activate-checkbox"
                    />
                    <p>{d}</p>
                  </div>
                </div>
              ))}
            </section>
          </div>
          <div className="flex-auto rounded-[10px] bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]">
            {Tabs[activeTab]}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OnBoard;
