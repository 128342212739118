import React, { useState } from "react";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import reviewImg from "../../images/reviewImg.png";
import reportsImg from "../../images/reportsImg.png";
import templateImg from "../../images/templateImg.png";
import generateImg from "../../images/generateImg.png";
import { Image } from "react-bootstrap";
import StudentReview from "./student_review/StudentReview";
import GenerateReport from "./generate_report/GenerateReport";
import ReportTemplate from "./report_template/ReportTemplate";
import RecentReports from "./recent_reports/RecentReports";

const Reporting = () => {
  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "Student Review",
      icon: <Image className="" src={reviewImg} alt="..." fluid />,
    },
    {
      name: "Generate Report",
      icon: <Image className="" src={generateImg} alt="..." fluid />,
    },
    {
      name: "Recent Reports",
      icon: <Image className="" src={reportsImg} alt="..." fluid />,
    },
    {
      name: "Report Template",
      icon: <Image className="" src={templateImg} alt="..." fluid />,
    },
  ];

  //Tab active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === tabActive) {
      return "w-[180px] h-auto flex flex-col items-center justify-center py-4 bg-gradient-to-b from-gradient-deepPinkStart to-gradient-deepPinkEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 1 && i === tabActive) {
      return "w-[180px] h-auto flex flex-col items-center justify-center py-4 bg-gradient-to-b from-gradient-violetStart to-gradient-violetEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 2 && i === tabActive) {
      return "w-[180px] h-auto flex flex-col items-center justify-center py-4 bg-gradient-to-b from-gradient-lightGreenEnd to-gradient-lightGreenStart text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 3 && i === tabActive) {
      return "w-[180px] h-auto flex flex-col items-center justify-center py-4 bg-gradient-to-b from-gradient-orangeStart to-gradient-orangeEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else {
      return "w-[180px] h-auto flex flex-col items-center justify-center py-4 text-defaultText bg-defaultWhite shadow-sm rounded-[20px]";
    }
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar reportActive="active" reportIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Reporting" />

          <div className="mt-[15px]">
            {/* right side */}
            <Tabs>
              <TabList
                className="flex gap-[12px] mb-[20px] pb-2 overflow-x-auto"
                id="horizontalTab"
              >
                {tabNames.map((item, index) => (
                  <Tab key={index} className="bg-transparent">
                    <button
                      onClick={setTabActive.bind(this, index)}
                      key={index}
                      className={showActive(index)}
                    >
                      {item.icon}
                      <Typography.MediumText text={item.name} xStyle="mt-2" />
                    </button>
                  </Tab>
                ))}
              </TabList>

              <hr className="mb-[20px]" />

              <TabPanel>
                <StudentReview />
              </TabPanel>
              <TabPanel>
                <GenerateReport />
              </TabPanel>
              <TabPanel>
                <RecentReports />
              </TabPanel>
              <TabPanel>
                <ReportTemplate />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reporting;
