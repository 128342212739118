import { SmallButtons } from "../buttons/SmallButtons";
import NavSearchBar from "./NavSearchBar";
import ProfilePic from "./ProfilePic";

const Navbar = () => {
  return (
    <nav className="px-[16px] py-[20px] z-10 w-full">
      <div className="flex justify-between">
        <div className="hidden md:block step3">
          <NavSearchBar />
        </div>

        <div className="flex gap-[20px] items-center">
          <div className="hidden md:block step4">
            <SmallButtons.Filled btnName="Send Excusal letter" />
          </div>

          <ProfilePic />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
