import {
  ACCOUNTS_URL,
  COOKIES_DOMAIN,
  COOKIES_TOKEN_NAME,
  COOKIES_USER_DATA,
  COOKIES_USER_VERIFIED,
} from "./constants";

export const redirect = (to: any) => {
  window.location = to;
};

const COOKIES_DOMAINNN =
  window.location.protocol === "https:" ? COOKIES_DOMAIN : "localhost";

export const setCookie = (cname: string, cvalue: any, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  const isSecure = window.location.protocol === "https:";
  const secure = isSecure ? "Secure;" : "";
  const sameSite = isSecure ? "SameSite=None;" : "";

  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${COOKIES_DOMAINNN};${secure}${sameSite}`;
};

export const getCookie = (cookieName: any) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(";");
  for (let index = 0; index < cookiesArray.length; index++) {
    let cookie = cookiesArray[index];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};

export const deleteCookie = (name: string) => {
  window.document.cookie = `${name}=;domain=${COOKIES_DOMAIN};path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const PerformLogout = () => {
  deleteCookie(COOKIES_TOKEN_NAME);
  deleteCookie(COOKIES_USER_DATA);
  deleteCookie(COOKIES_USER_VERIFIED);
  localStorage.clear();
  redirect(`${ACCOUNTS_URL}/?appref=${window.location.href}`);
};
