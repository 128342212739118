import { ClockIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { OtherButtons } from "../../components/buttons/OtherButtons";
import { Typography } from "../../components/typography/Typography";

const TodaysActivities = () => {
  return (
    <>
      <ActivityDataMap />
      <ActivityDataMap />
      <ActivityDataMap />

      <div className="flex justify-end">
        <OtherButtons.SeeAll
          btnName="See all"
          onClick={() => alert("Seeing all")}
        />
      </div>
    </>
  );
};

//data mapper
const ActivityDataMap = () => {
  return (
    <div className="flex gap-[20px] items-center mb-[10px]">
      <div className="w-[38px] h-[38px] rounded-[10px] bg-defaultBlue_20 flex justify-center items-center">
        <EnvelopeIcon className="w-[15px] h-[15px] text-defaultBlue" />
      </div>

      <div>
        <Typography.MediumText
          text="Rob - Servo motos"
          xStyle="text-defaultBlue"
        />

        <div className="flex gap-[8px] item-center">
          <ClockIcon className="w-[15px] h-[15px] text-defaultYellow" />

          <Typography.SmallText
            text="4:00 PM"
            xStyle="font-medium text-defaultGray"
          />
        </div>
      </div>
    </div>
  );
};

export default TodaysActivities;
