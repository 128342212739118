import CurrentCourseAccordion from "./CurrentCourseAccordion";

const Completed = () => {
  return (
    <>
      <CurrentCourseAccordion />
      <CurrentCourseAccordion />
    </>
  );
};

export default Completed;
