import { MingoInput } from "../../../../../components/inputs/MingoInput";

const Letter = () => {
  return (
    <div className="relative">
      <MingoInput.InputWithTools height="h-[430px]" />

      <div className="mt-[60px]">
        <div className="flex justify-between items-center">
          <button className="border-[2px] border-defaultBlue text-defaultBlue px-3 py-1 rounded-[20px] h-[40px] w-auto">
            Preview Letter
          </button>
          <button className="bg-defaultBlue text-white h-[40px] w-auto py-1 px-3 rounded-[20px]">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Letter;
