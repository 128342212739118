import { Typography } from "../../components/typography/Typography";
import { Col, Row } from "react-bootstrap";

const ContactDetails = () => {
  return (
    <>
      <div className="bg-defaultWhite shadow-sm pb-[40px] mb-[10px] w-full rounded-[20px] px-[30px] py-[25px] text-defaultText">
        <Typography.MediumText text="Contact Details" xStyle="font-semibold" />
        <hr className="mt-2" />

        <Row>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Contact 1" />
            <Typography.MediumText text="+233000221456" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Country" />
            <Typography.MediumText text="Ghana" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="State" />
            <Typography.MediumText text="Igbo State" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Contact 2" />
            <Typography.MediumText text="+233000221456" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="City" />
            <Typography.MediumText text="Accra" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Address" />
            <Typography.MediumText text="Doe Street" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactDetails;
