import { useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { MagnifyingGlassIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import CourseGroupTable from "./CourseGroupTable";
import AddYearGroupModal from "./addYear/AddYearGroupModal";

const CourseGroup = () => {
  const [showYearModal, setShowYear] = useState(false);

  const handleYearClose = () => setShowYear(false);
  const handleYearShow = () => setShowYear(true);

  return (
    <>
      <div className="flex flex-row overflow-hidden h-[100vh] w-full">
        {/* Sidebar */}
        <ProSideBar
          courseGroupActive="active"
          courseGroupIcon="dashIconActive"
        />

        {/* Content */}
        <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
          <Navbar />

          {/* main content */}
          <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
            <Typography.HeadingThree text="Course Group" />

            <Row className="mt-[10px]">
              {/* left side section */}
              <Col md={9} className="mt-[5px]">
                <div className="rounded-[20px] flex justify-between items-center mt-[15px]">
                  <div>
                    <Form>
                      <Form.Group className="relative">
                        <Form.Control
                          className="h-[40px] w-[339px] rounded-[25px] shadow-none border-defaultBlue_40 focus:border-defaultBlue_40 pr-8"
                          placeholder="Search"
                        />
                        <MagnifyingGlassIcon
                          className="w-5 h-5 text-defaultBlue_40 absolute right-3 bottom-2.5"
                          type="submit"
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <div>
                    <Form.Select
                      aria-label="Default select example"
                      className="shadow-none border-defaultBlue_40 focus:border-defaultBlue_40 h-[40px] w-[191px] rounded-[20px]"
                    >
                      <option value="paid">Newest</option>
                      <option value="unpaid">Oldest</option>
                    </Form.Select>
                  </div>
                </div>
                {/* courses table */}
                <CourseGroupTable />
              </Col>

              {/* right side section */}
              <Col md={3} className="mt-[5px]">
                <button
                  onClick={() => handleYearShow()}
                  className="w-[170px] h-[40px] rounded-[5px] shadow-sm bg-defaultBlue text-white flex justify-center items-center gap-2"
                >
                  <UserPlusIcon className="w-4 h-4" />
                  Add Year Group
                </button>
                <button className="mt-[20px] w-[192px] h-[40px] shadow-sm rounded-[5px] border-[1px] border-defaultBlue_40 bg-white text-defaultBlue flex justify-center items-center gap-2">
                  <UserPlusIcon className="w-4 h-4" />
                  Transition Group
                </button>

                <div className="mt-[17px]">
                  <Typography.MediumText
                    text="Start & End Term"
                    xStyle="font-semibold"
                  />
                  <Typography.MediumText
                    text="Term 1 :  June 2022 - September 2022"
                    xStyle="mt-[5px]"
                  />
                  <Typography.MediumText text="Term 2 :  October 2022 - January 2022" />
                </div>
                <hr className="my-[10px]" />
                <div>
                  <Typography.MediumText
                    text="Instructors"
                    xStyle="font-semibold"
                  />
                  <div className="flex -space-x-1 overflow-hidden text-defaultBlue mt-[5px]">
                    <Image
                      className="inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                      fluid
                    />
                    <Image
                      className="inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                      fluid
                    />
                    <Image
                      className="inline-block object-cover h-6 w-6 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                      alt=""
                      fluid
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <AddYearGroupModal
        showYearModal={showYearModal}
        handleYearClose={handleYearClose}
      />
    </>
  );
};

export default CourseGroup;
