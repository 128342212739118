import { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import videoIcon from "../../../../images/videoIcon.png";
import readIcon from "../../../../images/readIcon.png";
import quizIcon from "../../../../images/quizIcon.png";
import {
  ArrowDownTrayIcon,
  ChatBubbleLeftEllipsisIcon,
  DocumentPlusIcon,
  FlagIcon,
  HandThumbUpIcon,
  QueueListIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Typography } from "../../../../components/typography/Typography";

const QuickBrowse = () => {
  const navigate = useNavigate();
  const [sectionActive, setSectionActive] = useState(0);
  // section names
  const sectionNames = [
    {
      title: "Focus of this course",
      time: "10 mins",
      icon: <Image className="" src={videoIcon} alt="..." fluid />,
    },
    {
      title: "Must Read",
      time: "10 mins",
      icon: <Image className="" src={readIcon} alt="..." fluid />,
    },
    {
      title: "Quiz",
      time: "10 mins",
      icon: <Image className="" src={quizIcon} alt="..." fluid />,
    },
  ];

  //section active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === sectionActive) {
      return "w-[240px] h-[55px] bg-defaultBlue_5 px-[15px] py-[5px] mt-[10px] rounded-r-full flex gap-[17px] cursor-pointer";
    } else if (i === 1 && i === sectionActive) {
      return "w-[240px] h-[55px] bg-defaultBlue_5 px-[15px] py-[5px] mt-[10px] rounded-r-full flex gap-[17px] cursor-pointer";
    } else if (i === 2 && i === sectionActive) {
      return "w-[240px] h-[55px] bg-defaultBlue_5 px-[15px] py-[5px] mt-[10px] rounded-r-full flex gap-[17px] cursor-pointer";
    } else {
      return "w-[240px] h-[55px] px-[15px] py-[5px] mt-[10px] flex gap-[17px] cursor-pointer";
    }
  };

  return (
    <>
      <Row>
        {/* left side */}
        <Col md={3} className="mt-[10px]">
          <div className="bg-defaultWhite rounded-[20px] h-[537px] py-[15px] text-defaultText">
            <Typography.RegularText
              text="Course Content"
              xStyle="font-semibold pl-[15px]"
            />

            <div className="mt-[10px]">
              <Typography.MediumText
                text="Unit 1: Component"
                xStyle="font-semibold pl-[15px]"
              />

              {sectionNames.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={showActive(index)}
                    onClick={setSectionActive.bind(this, index)}
                  >
                    <div className="flex items-center">{item.icon}</div>
                    <div>
                      <Typography.MediumText text={item.title} />
                      <Typography.SmallText
                        text={item.time}
                        xStyle="opacity-50"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Col>

        {/* right side */}
        <Col md={9} className="mt-[10px]">
          {sectionActive === 0 ? (
            <>
              <div>
                <div className="bg-defaultWhite rounded-[20px] h-[584px] p-[15px]">
                  <div>
                    <iframe
                      className="rounded-[10px]"
                      width="100%"
                      height="500"
                      src="https://www.youtube.com/embed/f2DIwDzgb2o"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                      allowFullScreen
                    ></iframe>
                  </div>

                  <div className="mt-[20px] flex justify-between items-center">
                    <div className="flex gap-[40px]">
                      <button className="flex gap-2 text-defaultBlue font-semibold">
                        <DocumentPlusIcon className="w-5 h-5" />
                        Add Note
                      </button>
                      <button className="flex gap-2 text-defaultBlue font-semibold">
                        <ChatBubbleLeftEllipsisIcon className="w-5 h-5" />
                        Go to forum
                      </button>
                    </div>

                    <div className="flex gap-[40px]">
                      <button className="flex gap-2 text-defaultBlue font-semibold">
                        <ArrowDownTrayIcon className="w-5 h-5" />
                        Download
                      </button>
                      <button className="flex gap-2 text-defaultBlue font-semibold">
                        <QueueListIcon className="w-5 h-5" />
                        Transcript
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex gap-[28px] mt-[20px]">
                  <button className="bg-defaultBlue text-defaultWhite w-[182px] h-[40px] rounded-[20px]">
                    Mark as completed
                  </button>

                  <button
                    onClick={() => navigate("/book_instructor")}
                    className="text-defaultBlue bg-defaultWhite flex gap-2 items-center justify-center border-[1px] border-defaultBlue w-[182px] h-[40px] rounded-[20px]"
                  >
                    <UserCircleIcon className="w-5 h-5" />
                    Meet Instructor
                  </button>

                  <button className="flex gap-2 items-center justify-center text-defaultBlue font-semibold">
                    <HandThumbUpIcon className="w-5 h-5" />
                    Like
                  </button>

                  <button className="flex gap-2 items-center justify-center text-defaultBlue font-semibold">
                    <FlagIcon className="w-5 h-5" />
                    Report an issue
                  </button>
                </div>
              </div>
            </>
          ) : sectionActive === 1 ? (
            <>
              <div>
                <div className="bg-defaultWhite rounded-[20px] h-[584px] p-[15px]">
                  ...read
                </div>
              </div>
            </>
          ) : sectionActive === 2 ? (
            <div>
              <div className="bg-defaultBlue text-defaultWhite shadow-sm rounded-[20px] h-[131px] px-[30px] pt-[27px]">
                <div className="flex justify-between items-center">
                  <div>
                    <Typography.HeadingThree text="Advance Robotics" />
                    <div className="mt-[10px] flex gap-[20px]">
                      <Typography.SmallText
                        text="Graded Quiz"
                        xStyle="bg-tertiary-orange px-2 py-[2px] rounded-[30px]"
                      />
                      <Typography.MediumText text="40 mins" />
                      <Typography.MediumText text="Total Points - 20" />
                    </div>
                  </div>
                  <button
                    className="w-[172px] h-[52px] bg-defaultYellow rounded-[33px]"
                    onClick={() => navigate("/quiz")}
                  >
                    Start Quiz
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};

export default QuickBrowse;
