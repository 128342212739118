import { Typography } from "../../components/typography/Typography";
import { Col, Row } from "react-bootstrap";

const WorkDetails = () => {
  return (
    <>
      <div className="bg-defaultWhite shadow-sm pb-[40px] mb-[10px] w-full rounded-[20px] px-[30px] py-[25px] text-defaultText">
        <Typography.MediumText text="Work Details" xStyle="font-semibold" />
        <hr className="mt-2" />

        <Row>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Department" />
            <Typography.MediumText text="Instructors & Hardware" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Country" />
            <Typography.MediumText text="Ghana" />
          </Col>
          <Col md={4} className="pt-[20px]">
            <Typography.SmallText text="Work Location" />
            <Typography.MediumText text="Igbo State" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default WorkDetails;
