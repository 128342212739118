import { Col, Form, Modal, Row } from "react-bootstrap";
import { Typography } from "../../components/typography/Typography";

interface modalProps {
  showModal: boolean;
  handleModalClose: any;
}

const EditProfileModal = ({ showModal, handleModalClose }: modalProps) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        size="lg"
        className="rounded-[20px] border-none text-defaultText"
      >
        <Modal.Body className="py-[15px] px-[35px]">
          <Typography.MediumText
            text="Personal Details"
            xStyle="font-semibold"
          />

          <hr />

          <div className="mt-[20px]">
            <Form as={Row}>
              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  Last Name
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="Last Name"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  Prefered Name
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="Prefered Name"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  First Name
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="First Name"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  date of Birth
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="date"
                  placeholder="Date of Birth"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  Middle Name
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="Middle Name"
                />
              </Form.Group>

              <hr className="my-[20px]" />

              <Typography.MediumText
                text="Contact Details"
                xStyle="font-semibold mb-[16px]"
              />

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  Email
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="email"
                  placeholder="Email"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  Country
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="Country"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  Contact 1
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="Contact 1"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  City
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="City"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  Contact 2
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="Contact 2"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  State
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="State"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  Address
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="Address"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                className="mb-3"
                controlId="formBasicText"
              >
                <Form.Label className="text-defaultText opacity-60">
                  House No. / Apartment No.
                </Form.Label>
                <Form.Control
                  className="h-[40px] shadow-none outline outline-1 outline-defaultBlue_20 rounded-[5px]"
                  type="text"
                  placeholder="House No. / Apartment No."
                />
              </Form.Group>

              <hr className="mt-[40px] mb-[20px]" />

              <div className="flex gap-[20px] justify-end items-center mb-[20px]">
                <button
                  className="text-tertiary-red"
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
                <button
                  className="text-defaultWhite bg-defaultBlue rounded-[20px] px-3 py-1"
                  onClick={() => alert("saved")}
                >
                  Save Changes
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProfileModal;
