import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import BaseService from "../../../helpers/baseServices";

interface theProps {
  onChange: (selected: string[]) => void;
  gradeLevelIds?: string[];
}

interface GradeLevelProps {
  label: string;
  value: string;
}

const SelectGradeLevel = ({ onChange, gradeLevelIds }: theProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allGradeLevel, setAllGradeLevel] = useState<GradeLevelProps[]>([]);
  const [selectedGradeLevels, setSelectedGradeLevels] = useState<string[]>(
    gradeLevelIds || []
  );

  const fetchGradeLevel = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await BaseService.get_api("/robocentre/age-level");
      if (data?.payload) {
        const arrangedResponse: GradeLevelProps[] = data.payload.map(
          (item: any) => ({
            label: item.name,
            value: item._id,
          })
        );
        setAllGradeLevel(arrangedResponse);
        console.log("arrangedResponse : ", arrangedResponse);
      }
    } catch (error) {
      console.error("Failed to fetch grade levels:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchGradeLevel();
  }, [fetchGradeLevel]);

  // Update selected grade levels when gradeLevelIds prop changes
  useEffect(() => {
    if (gradeLevelIds && gradeLevelIds.length > 0) {
      setSelectedGradeLevels(gradeLevelIds);
    }
  }, [gradeLevelIds]);

  const handleChange = useCallback(
    (value: string[]) => {
      setSelectedGradeLevels(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <div>
      <label className="block text-[#0F0F37] mb-1">Grade Level</label>
      <Select
        id="category"
        value={selectedGradeLevels}
        className="h-[48px] w-full"
        options={allGradeLevel}
        onChange={handleChange}
        disabled={isFetching}
        loading={isFetching}
        placeholder="Select Grade Level"
        allowClear
        mode="multiple"
      />
    </div>
  );
};

export default SelectGradeLevel;
