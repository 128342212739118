import { ClockIcon } from "@heroicons/react/24/outline";
import { Form } from "react-bootstrap";
import { Typography } from "../../../../components/typography/Typography";

const AllTasksTab = () => {
  return (
    <>
      <div className="flex justify-end">
        <Form.Select className="w-[129px] shadow-none border-[2px] border-defaultBlue_40">
          <option>All</option>
          <option>Deadline</option>
          <option>Tasks</option>
          <option>Events</option>
        </Form.Select>
      </div>

      <Typography.MediumText
        text="Today"
        xStyle="font-semibold text-defaultText"
      />

      <SampleTask />
      <SampleTask />

      <Typography.MediumText
        text="Next Week"
        xStyle="font-semibold text-defaultText mt-[30px]"
      />

      <SampleTask />
      <SampleTask />
    </>
  );
};

// data mapper
const SampleTask = () => {
  return (
    <div className="bg-defaultWhite border-[1px] border-defaultBlue_40 h-[66px] rounded-[20px] mt-[10px] py-[10px] px-[20px]">
      <div className="flex justify-between items-center">
        {/* left */}
        <div className="flex gap-[20px] items-center">
          <div className="w-[38px] h-[40px] rounded-[10px] bg-defaultBlue_20 flex flex-col justify-center items-center">
            <div className="bg-defaultBlue text-defaultWhite w-full h-[16px] rounded-full flex justify-center items-center">
              <Typography.SmallText text="Feb" />
            </div>
            <Typography.MediumText text="24" xStyle="text-defaultBlue" />
          </div>

          <div>
            <Typography.MediumText
              text="Lecture 1"
              xStyle="text-defaultBlue font-semibold"
            />

            <div className="flex gap-[8px] item-center">
              <p className="bg-tertiary-pink text-defaultWhite text-center h-[17px] font-medium text-[12px] rounded-[20px] w-auto px-[6px]">
                Homework
              </p>

              <ClockIcon className="w-5 h-5 text-defaultYellow" />

              <Typography.SmallText
                text="8:00 AM - 4:00 PM"
                xStyle="font-medium text-defaultGray"
              />
            </div>
          </div>
        </div>

        {/* right */}

        <div className="flex gap-[20px] items-center">
          <button className="text-defaultWhite bg-secondary-green w-[87px] h-[30px] rounded-[20px]">
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllTasksTab;
