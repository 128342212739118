
import { ACCOUNTS_URL, COOKIES_TOKEN_NAME } from "../helpers/constants";
import { getCookie } from "../helpers/utils";

// protected routes
const ProtectedRoutes = ({ children }: any) => {

    const token = getCookie(COOKIES_TOKEN_NAME);

    if (token) {
        return children;
    } else {
        return window.location.href = `${ACCOUNTS_URL}/?appref=${window.location.href}`;
    }
};

export default ProtectedRoutes;
