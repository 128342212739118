import CurrentCourseAccordion from "./CurrentCourseAccordion";

const All = () => {
  return (
    <>
      <CurrentCourseAccordion />
      <CurrentCourseAccordion />
    </>
  );
};

export default All;
