import { OtherButtons } from "../../components/buttons/OtherButtons";
import { Typography } from "../../components/typography/Typography";

const UpcomingEvents = () => {
  return (
    <>
      <EventsDataMap />
      <EventsDataMap />

      <div className="flex justify-end">
        <OtherButtons.SeeAll
          btnName="See all"
          onClick={() => alert("Seeing all")}
        />
      </div>
    </>
  );
};

//data mapper
const EventsDataMap = () => {
  return (
    <div className="flex gap-[20px] items-center mb-[13px]">
      <div className="w-[38px] h-[40px] rounded-[10px] bg-defaultBlue_20 flex flex-col justify-center items-center">
        <div className="bg-defaultBlue text-defaultWhite w-full h-[16px] rounded-full flex justify-center items-center">
          <Typography.SmallText text="Feb" />
        </div>
        <Typography.MediumText text="24" xStyle="text-defaultBlue" />
      </div>

      <div>
        <Typography.MediumText text="Robocode Hour" />

        <div className="flex gap-[8px] item-center">
          <p className="bg-secondary-orange text-defaultWhite text-center font-medium text-[12px] rounded-[20px] w-[50px]">
            Late
          </p>

          <Typography.SmallText
            text="8:00 AM - 4:00 PM"
            xStyle="font-medium text-defaultGray"
          />
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
