import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Typography } from "../../../../components/typography/Typography";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { OtherButtons } from "../../../../components/buttons/OtherButtons";
import ProSideBar from "../../../../components/sidebar/pro-sidebar";
import Navbar from "../../../../components/navbar/Navbar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Overview from "./tabs/Overview";
import Cover from "./tabs/Cover";
import Letter from "./tabs/Letter";
import Summary from "./tabs/Summary";
import ClassReports from "./tabs/ClassReports";
import Attendance from "./tabs/Attendance";

const Preferences = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);

  const tabList = [
    "Overview",
    "Cover",
    "Letter",
    "Summary",
    "Class Reports",
    "Attendance",
  ];

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar reportActive="active" reportIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <div className="flex gap-[10px] items-center">
            <OtherButtons.BackButton onClick={() => navigate("/reporting")} />
            <Typography.HeadingThree text="Report" />
          </div>

          <Row className="mt-4">
            {/* left side */}
            <Col md={9}>
              <Tabs>
                <TabList className="flex">
                  {tabList.map((_d, i) => (
                    <Tab
                      className={`${
                        activeTab === i
                          ? "border-0 bg-white cursor-pointer px-4 py-1 rounded-t-[10px]"
                          : "border-0 bg-[#E0E0FB] cursor-pointer px-4 py-1 rounded-t-[10px] focus:border-0"
                      }`}
                      key={i}
                      onClick={() => setActiveTab(i)}
                    >
                      {_d}
                    </Tab>
                  ))}
                </TabList>
                <TabPanel>
                  <div className="w-full h-auto rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
                    <Overview />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="w-full h-auto rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
                    <Cover />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="w-full h-screen rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
                    <Letter />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="w-full rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
                    <Summary />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="w-full rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
                    <ClassReports />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="w-full rounded-b-[20px] rounded-tr-[20px] bg-white p-[25px]">
                    <Attendance />
                  </div>
                </TabPanel>
              </Tabs>
            </Col>

            {/* right side */}
            <Col md={3}>
              <button className="rounded-[5px] text-white bg-defaultBlue h-[40px] w-auto px-3 flex justify-center items-center gap-[10px]">
                <UserPlusIcon className="w-5 h-5" />
                Save Templates
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
