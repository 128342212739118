import React, { useEffect, useState } from "react";
import { DatePicker, Space } from "antd";
import moment from "moment"; // Ant Design's DatePicker uses moment.js
const { RangePicker } = DatePicker;

const EditDateRange = ({
  onDateData,
  meetingsDate,
}: {
  onDateData: any;
  meetingsDate: { start: string | null; end: string | null };
}) => {
  const [selectedDates, setSelectedDates] = useState<any>(null);

  useEffect(() => {
    if (meetingsDate?.start && meetingsDate?.end) {
      const startDate = moment(new Date(meetingsDate.start));
      const endDate = moment(new Date(meetingsDate.end));
      setSelectedDates([startDate, endDate]);
    }
  }, [meetingsDate]);

  const handleDateRangeChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const startOfDay = new Date(dates[0]);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(dates[1]);
      endOfDay.setHours(23, 59, 59, 999);

      const startDate = new Date(startOfDay).toISOString();
      const endDate = new Date(endOfDay).toISOString();
      onDateData({ start: startDate, end: endDate });
    } else {
      onDateData({ start: null, end: null });
    }
  };

  return (
    <div className="flex items-center gap-4 justify-between">
      <label className="w-[200px] block text-[#0F0F37] mb-1">
        Meeting Date
      </label>
      <RangePicker
        style={{
          height: "48px",
          width: "100%",
          background: "#fff",
        }}
        onChange={handleDateRangeChange}
        value={selectedDates}
      />
    </div>
  );
};

export default EditDateRange;
