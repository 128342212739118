import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Typography } from "../../../components/typography/Typography";
import ImgUploadComponent from "./ImgUploadComponent";
import { MingoInput } from "../../../components/inputs/MingoInput";

interface modalProps {
  showYearModal: boolean;
  handleYearClose: any;
}

const AddYearGroupModal = ({ showYearModal, handleYearClose }: modalProps) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const dummy_options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const handleSelect = (selected: any) => {
    console.log(selected);
    setSelectedOption(selected);
  };
  return (
    <>
      <Modal
        show={showYearModal}
        onHide={handleYearClose}
        size="lg"
        className="rounded-[20px] border-none text-defaultText"
      >
        <Modal.Body className="py-[15px] px-[35px]">
          <Typography.RegularText text="Add Year Group" />

          <hr />

          <div className="mt-[20px]">
            {/* image upload component */}
            <ImgUploadComponent />

            <div>
              <Row className="mt-[10px]">
                <Col md={4} className="mt-[5px] flex items-center">
                  <Typography.MediumText text="Name" />
                </Col>
                <Col md={8} className="mt-[5px]">
                  <MingoInput.Cornered
                    type="text"
                    placeholder="Name of year group"
                  />
                </Col>
              </Row>
              <Row className="mt-[5px]">
                <Col md={4} className="flex items-center">
                  <Typography.MediumText text="Grade" />
                </Col>
                <Col md={8}>
                  <MingoInput.OptionsSelect
                    options={<option value="One">One</option>}
                  />
                </Col>
              </Row>
              <Row className="mt-[5px]">
                <Col md={4} className="flex items-center">
                  <Typography.MediumText text="Duration" />
                </Col>
                <Col md={8}>
                  <MingoInput.OptionsSelect
                    options={
                      <>
                        <option value="One">One</option>
                        <option value="One">Two</option>
                      </>
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-[15px]">
                <Col md={4} className="mt-[5px]">
                  <Typography.MediumText text="Description" />
                </Col>
                <Col md={8} className="mt-[5px]">
                  <MingoInput.TextArea type="text" placeholder="Type here..." />
                </Col>
              </Row>
              <Row className="mt-[15px]">
                <Col md={4} className="mt-[5px]">
                  <Typography.MediumText text="Authors" />
                </Col>
                <Col md={8} className="mt-[5px]">
                  <MingoInput.MultiSelect
                    isMulti={true}
                    options={dummy_options}
                    value={selectedOption}
                    onChange={(e: any) => handleSelect(e)}
                  />
                </Col>
              </Row>
            </div>

            <hr className="mt-[40px] mb-[20px]" />

            <div className="flex gap-[20px] justify-end items-center mb-[20px]">
              <button className="text-tertiary-red" onClick={handleYearClose}>
                Cancel
              </button>
              <button
                className="text-defaultWhite bg-defaultBlue rounded-[20px] px-3 py-1"
                onClick={() => alert("Added")}
              >
                Add Year Group
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddYearGroupModal;
