import { Col, Row } from "react-bootstrap";
import { Typography } from "../../../../../components/typography/Typography";
import { MingoInput } from "../../../../../components/inputs/MingoInput";

const Overview = () => {
  return (
    <div>
      <Row>
        <Col md={3} className="mt-4">
          <Typography.MediumText text="Title" />
        </Col>
        <Col md={9}>
          <MingoInput.OptionsSelect
            options={
              <>
                <option>Regular Report</option>
                <option>Stabdard Report</option>
              </>
            }
          />
          <div className="mt-4">
            <Typography.SmallText
              text="Please use the checkboxes to indicate whether you would like to include each particular section in your reports. You can also sort the sections by using the reorder icon."
              xStyle="text-[#9D9CC2]"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={3} className="mt-4">
          <Typography.MediumText text="Report Layout" />
        </Col>
        <Col md={9} className="mt-4">
          <div className="mb-3">
            <MingoInput.CheckBox label="Cover Sheet" />
          </div>
          <div className="mb-3">
            <MingoInput.CheckBox label="Letter" />
          </div>
          <div className="mb-3">
            <MingoInput.CheckBox label="Learner Profile" />
          </div>
          <div className="mb-3">
            <MingoInput.CheckBox label="Summary of Achievement" />
          </div>
          <div className="mb-3">
            <MingoInput.CheckBox label="Attendance" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
