import { useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import { Typography } from "../../../components/typography/Typography";
import { Col, Row } from "react-bootstrap";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import LandingPage from "./LandingPage";
import CoursesPage from "./CoursesPage";
import MembersPage from "./MembersPage";
import MessagesPage from "./MessagesPage";
import { OtherButtons } from "../../../components/buttons/OtherButtons";

const AddClass = () => {
  const navigate = useNavigate();
  const [sectionActive, setSectionActive] = useState(0);
  // section names
  const sectionNames = [
    {
      title: "Class Landing Page",
      icon: <CheckCircleIcon className="w-5 h-5 text-green-600" />,
    },
    {
      title: "Courses",
      icon: <CheckCircleIcon className="w-5 h-5 text-green-600" />,
    },
    {
      title: "Members",
      icon: <CheckCircleIcon className="w-5 h-5 text-green-600" />,
    },
    {
      title: "Class Messages",
      icon: <CheckCircleIcon className="w-5 h-5 text-green-600" />,
    },
  ];

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] h-full overflow-y-auto">
          <div className="flex gap-[15px] items-center md:ml-[120px]">
            <OtherButtons.BackButton onClick={() => navigate("/my_class")} />
            <Typography.HeadingThree text="Add Class" />
          </div>

          <Row className="mt-[10px]">
            {/* left side section */}
            <Col md={1} className="mt-[5px]"></Col>
            <Col md={3} className="mt-[5px]">
              <div className="h-[347px] rounded-[20px] py-[30px] bg-white shadow-sm text-defaultText">
                <Typography.MediumText
                  text="Class Details"
                  xStyle="font-semibold pl-[20px]"
                />

                {sectionNames.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        sectionActive === index
                          ? `h-[55px] bg-defaultBlue_5 w-full px-[15px] py-[5px] mt-[10px] border-[5px] border-l-defaultBlue border-y-0 border-r-0 flex gap-[17px] cursor-pointer`
                          : `w-[240px] h-[55px] px-[15px] py-[5px] mt-[10px] flex gap-[17px] cursor-pointer`
                      }
                      onClick={() => setSectionActive(index)}
                    >
                      <div className="flex gap-[15px] items-center">
                        {item.icon}
                        <Typography.MediumText text={item.title} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>

            {/* right side section */}
            <Col md={7} className="mt-[5px]">
              {sectionActive === 0 ? (
                <div>
                  <LandingPage />
                  <div className="mt-[30px] flex gap-[20px] justify-end items-center">
                    <button className="w-[160px] h-[40px] flex gap-[5px] justify-center items-center rounded-[20px] border-[1px] border-defaultBlue text-defaultBlue">
                      <PlusIcon className="w-5 h-5" />
                      Save as draft
                    </button>
                    <button className="w-[160px] h-[40px] flex gap-[5px] justify-center items-center rounded-[20px] bg-defaultBlue text-white">
                      <PlusIcon className="w-5 h-5" />
                      Publish Course
                    </button>
                  </div>
                </div>
              ) : sectionActive === 1 ? (
                <CoursesPage />
              ) : sectionActive === 2 ? (
                <MembersPage />
              ) : sectionActive === 3 ? (
                <MessagesPage />
              ) : (
                <></>
              )}
            </Col>
            <Col md={1} className="mt-[5px]"></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddClass;
