import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Typography } from "../../components/typography/Typography";
import { OtherButtons } from "../../components/buttons/OtherButtons";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import Parents from "./Parents";
import Instructors from "./Instructors";
import Overview from "./tabs/overview/Overview";
import Dashboard from "./tabs/dashboard/Dashboard";
import Grades from "./tabs/grade/Grades";
import Badges from "./tabs/badges/Badges";
import Courses from "./tabs/courses/Courses";

const StudentDetails = () => {
  const navigate = useNavigate();
  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "Overview",
    },
    {
      name: "Dashboard",
    },
    {
      name: "Courses",
    },
    {
      name: "Calendar",
    },
    {
      name: "Timetable",
    },
    {
      name: "Grades",
    },
    {
      name: "Badges",
    },
  ];

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar courseGroupActive="active" courseGroupIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto overflow-x-hidden">
          <div className="flex gap-[20px] items-center">
            <OtherButtons.BackButton onClick={() => navigate("/year_group")} />
            <Typography.HeadingThree text="Mawuli Togbor" />
          </div>

          <Row>
            {/* left side */}
            <Col md={9}>
              <Tabs className="mt-[20px]">
                <TabList
                  className="flex gap-[12px] mb-[20px] pb-2 overflow-x-auto"
                  id="horizontalTab"
                >
                  {tabNames.map((item, index) => (
                    <Tab key={index} className="bg-transparent">
                      <button
                        onClick={() => setTabActive(index)}
                        key={index}
                        className={
                          tabActive === index
                            ? "w-auto h-[43px] flex items-center gap-[14px] px-3 py-1 bg-gradient-to-r from-gradient-blueStart to-gradient-blueEnd text-defaultWhite shadow-sm rounded-[20px]"
                            : "w-auto h-[43px] flex items-center gap-[14px] px-3 py-1 bg-defaultWhite shadow-sm rounded-[20px]"
                        }
                      >
                        <Typography.MediumText text={item.name} />
                      </button>
                    </Tab>
                  ))}
                </TabList>

                <hr className="mb-[15px] border-[1.5px] border-defaultBlue_60" />

                <TabPanel>
                  <Overview />
                </TabPanel>
                <TabPanel>
                  <Dashboard />
                </TabPanel>
                <TabPanel>
                  <Courses />
                </TabPanel>
                <TabPanel>..4</TabPanel>
                <TabPanel>..5</TabPanel>
                <TabPanel>
                  <Grades />
                </TabPanel>
                <TabPanel>
                  <Badges />
                </TabPanel>
              </Tabs>
            </Col>
            {/* right side */}
            <Col md={3}>
              <ProfilePicture />
              <hr className="my-[15px] border-[2px] border-defaultBlue_40" />
              <Parents />
              <hr className="my-[15px] border-[2px] border-defaultBlue_40" />
              <Instructors />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
