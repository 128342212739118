import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import BaseService from "../../../helpers/baseServices";

interface theProps {
  onChange: (selected: string) => void;
  gradeLevel?: string;
}

interface GradeLevelProps {
  label: string;
  value: string;
}

const GradeLevel = ({ onChange, gradeLevel }: theProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allGradeLevel, setAllGradeLevel] = useState<GradeLevelProps[]>([]);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState<string>(
    gradeLevel || ""
  );

  const fetchGradeLevel = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await BaseService.get_api("/robocentre/age-level");
      if (data?.payload) {
        const arrangedResponse: GradeLevelProps[] = data.payload.map(
          (item: any) => ({
            label: item.name,
            value: item._id,
          })
        );
        setAllGradeLevel(arrangedResponse);
      }
    } catch (error) {
      console.error("Failed to fetch grade levels:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchGradeLevel();
  }, [fetchGradeLevel]);

  useEffect(() => {
    if (gradeLevel) {
      setSelectedGradeLevel(gradeLevel);
    }
  }, [gradeLevel]);

  const handleChange = useCallback(
    (value: string) => {
      setSelectedGradeLevel(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className="flex items-center gap-4 justify-between">
      <label className="w-[200px] block text-[#0F0F37] mb-1">Grade Level</label>
      <Select
        id="category"
        value={selectedGradeLevel || undefined}
        className="h-[48px] w-full"
        options={allGradeLevel}
        onChange={handleChange}
        disabled={isFetching}
        loading={isFetching}
        placeholder="Select Grade Level"
        allowClear
      />
    </div>
  );
};

export default GradeLevel;
