import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import {
  PencilSquareIcon,
  ArrowsPointingOutIcon,
  TrashIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import { Typography } from "../../components/typography/Typography";
import { Col, Row } from "react-bootstrap";
import { MingoInput } from "../../components/inputs/MingoInput";
import { v4 as uuidv4 } from "uuid";

interface compoProps {
  id: string;
  component: JSX.Element;
}

const QuestionComponent = ({ questionNum, question, marks, onDelete }: any) => {
  //list of options
  const [components, setComponents] = useState<compoProps[]>([]);

  //add option
  const handleAddNewComponent = () => {
    const newComponent = {
      id: uuidv4(),
      component: <OptionComponent key={uuidv4()} onDelete={handleDelete} />,
    };
    setComponents([...components, newComponent]);
  };

  //delete option
  const handleDelete = (id: any) => {
    setComponents(components.filter((component: any) => component.id !== id));
  };

  return (
    <div className="mx-auto w-full mb-[10px]">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`${
                open
                  ? "flex items-center justify-between w-full rounded-t-[20px] bg-white px-4 py-2 text-defaultText border-t-[1px] border-defaultBlue_40"
                  : "flex items-center justify-between w-full rounded-[20px] bg-white px-4 py-2 text-defaultText shadow-sm border-[1px] border-defaultBlue_40"
              }`}
            >
              <div className="flex items-center gap-[10px]">
                <ArrowsPointingOutIcon className="h-5 w-5 text-defaultBlue" />
                <div className="flex gap-[10px] items-center">
                  <Typography.MediumText text="Q1." xStyle="font-semibold" />
                  <Typography.MediumText text="What is your name?" />
                </div>
                <PencilSquareIcon className="w-5 h-5 text-defaultBlue" />
              </div>
              <div className="flex items-center gap-[5px]">
                <Typography.SmallText text="1 Mark" />
                <TrashIcon
                  className="w-5 h-5 text-defaultBlue"
                  onClick={onDelete}
                />
              </div>
            </Disclosure.Button>
            {open ? <hr /> : <></>}
            <Disclosure.Panel
              className={`${
                open
                  ? "px-4 pt-1 pb-[40px] text-defaultText bg-white shadow-sm border-b-[1px] border-defaultBlue_40 rounded-b-[20px]"
                  : "px-4 pt-1 pb-[40px] text-defaultText bg-white shadow-sm border-[1px] border-defaultBlue_40 rounded-[20px]"
              }`}
            >
              <div>
                <Row>
                  <Col md={4} className="flex items-center">
                    <Typography.MediumText text="Quiz Type" />
                  </Col>
                  <Col md={8}>
                    <MingoInput.OptionsSelect
                      options={
                        <>
                          <option>Multiple Chioice</option>
                          <option>True / False</option>
                          <option>Short Answer</option>
                          <option>Matching</option>
                        </>
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>
                    <Typography.MediumText text="Quiz Question" />
                  </Col>
                  <Col md={8}>
                    <MingoInput.InputWithTools />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4}>
                    <Typography.MediumText text="Answers" />
                  </Col>
                  <Col md={8}>
                    <div className="w-full relative">
                      {components.map(({ id, component }) => (
                        <div key={id}>
                          {React.cloneElement(component, {
                            onDelete: () => handleDelete(id),
                          })}
                        </div>
                      ))}
                      <button
                        onClick={handleAddNewComponent}
                        className="text-defaultBlue flex gap-[5px] items-center absolute right-0"
                      >
                        <SquaresPlusIcon className="w-5 h-5" />
                        Add Option
                      </button>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col md={4}>
                    <Typography.MediumText text="Mark(s)" />
                  </Col>
                  <Col md={8} className="w-[150px]">
                    <MingoInput.Cornered type="text" />
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col md={4}>
                    <Typography.MediumText text="Feedback for correct answer" />
                  </Col>
                  <Col md={8}>
                    <MingoInput.InputWithTools />
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col md={4}>
                    <Typography.MediumText text="Feedback for wrong answer" />
                  </Col>
                  <Col md={8}>
                    <MingoInput.InputWithTools />
                  </Col>
                </Row>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

//options component
const OptionComponent = ({ onDelete }: any) => {
  return (
    <div className="flex gap-[5px] items-center mb-2">
      <MingoInput.CheckBox />
      <MingoInput.Cornered type="text" placeholder="Type option here" />
      <TrashIcon
        className="h-5 w-5 text-defaultBlue cursor-pointer"
        onClick={onDelete}
      />
    </div>
  );
};

export default QuestionComponent;
