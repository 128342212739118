import { Typography } from "../../../components/typography/Typography";
import CourseComponent from "./CourseComponent";
import { MingoInput } from "../../../components/inputs/MingoInput";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const CoursesPage = () => {
  return (
    <div className="pb-[40px] rounded-[20px] bg-white px-[30px] py-[20px] shadow-sm text-defaultText">
      <Typography.RegularText text="Courses" />
      <hr />

      <div className="mt-[20px] relative">
        <MingoInput.Rounded
          type="text"
          placeholder="Search  and add student to class"
        />
        <MagnifyingGlassIcon className="w-5 h-5 text-defaultBlue_40 absolute bottom-[10px] right-0 mr-3 cursor-pointer" />
      </div>

      <div className="mt-[20px]">
        <CourseComponent
          image="https://pimages.toolbox.com/wp-content/uploads/2023/02/10044719/Picture-of-a-motherboard.jpg"
          title="Basic Electronics"
          unit={8}
          tasks={6}
          maxPoints={100}
        />
        <CourseComponent
          image="https://pimages.toolbox.com/wp-content/uploads/2023/02/10044719/Picture-of-a-motherboard.jpg"
          title="Basic Electronics"
          unit={8}
          tasks={6}
          maxPoints={100}
        />
        <CourseComponent
          image="https://pimages.toolbox.com/wp-content/uploads/2023/02/10044719/Picture-of-a-motherboard.jpg"
          title="Basic Electronics"
          unit={8}
          tasks={6}
          maxPoints={100}
        />
      </div>

      <div className="mt-[20px]">
        <button className="font-semibold text-defaultBlue hover:border-b-[2px] hover:border-defaultBlue">
          + Add New
        </button>
      </div>
    </div>
  );
};

export default CoursesPage;
