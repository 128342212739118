import React, { useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Typography } from "../../../components/typography/Typography";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const CourseComponent = ({
  image,
  title,
  unit,
  tasks,
  maxPoints,
  onEditClick,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="bg-white text-defaultText rounded-[20px] shadow-sm min-h-[106px] p-[10px] flex justify-between items-center mt-[10px]">
      {/* image section */}
      <div className="flex gap-[20px]">
        <Image
          className="h-[86px] w-[91px] rounded-[12px] object-cover"
          src={image}
          alt="..."
          fluid
        />
        <div className="mt-2">
          <Typography.MediumText
            text={title}
            xStyle="font-semibold text-defaultBlue"
          />

          <div className="mt-2 flex gap-[5px]">
            <Typography.ExtraSmallText
              text="Live"
              xStyle="bg-tertiary-red text-white rounded-[10px] w-[44px] h-[16px] text-center"
            />
            <Typography.ExtraSmallText
              text="Public"
              xStyle="bg-defaultBlue_60 text-white rounded-[10px] w-[44px] h-[16px] text-center"
            />
          </div>
        </div>
      </div>
      {/* points section */}
      <div className="flex gap-[80px]">
        <div className="text-center">
          <Typography.HeadingThree text={unit} xStyle="text-secondary-green" />
          <Typography.MediumText text="Unit" />
        </div>
        <div className="text-center">
          <Typography.HeadingThree text={tasks} xStyle="text-defaultYellow" />
          <Typography.MediumText text="Tasks" />
        </div>
        <div className="text-center">
          <Typography.HeadingThree text={maxPoints} xStyle="text-defaultBlue" />
          <Typography.MediumText text="Max Points" />
        </div>
        <div className="flex gap-[10px]">
          <PencilSquareIcon
            className="w-5 h-5 cursor-pointer text-defaultBlue"
            onClick={onEditClick}
          />
          <Dropdown onToggle={(isOpen) => setIsOpen(isOpen)} show={isOpen}>
            <Dropdown.Toggle as={CustomToggle} />

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const CustomToggle = React.forwardRef((onClick: any, ref) => (
  <EllipsisVerticalIcon
    className="h-6 w-6 text-defaultBlue font-semibold cursor-pointer"
    onClick={onClick}
  />
));

export default CourseComponent;
