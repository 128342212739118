import { Image } from "react-bootstrap";
import { Typography } from "../../../../components/typography/Typography";

const CurrentCourseAccordion = () => {
  // custom dropdown
  // const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  //   <Link className="w-[24px] h-[24px]" to="#" onClick={onClick}>
  //     <EllipsisVerticalIcon className="w-5 h-5 text-defaultBlue" />
  //   </Link>
  // ));

  return (
    <div className="mb-3">
      <div className="flex gap-[70px] border-[1.5px] border-defaultBlue_40 p-[10px] rounded-[10px]">
        {/* left flex*/}
        <div className="flex gap-[20px] j items-center">
          <Image
            className="w-[91px] h-[86px] rounded-[12px] object-cover"
            src="https://www.rd.com/wp-content/uploads/2022/09/GettyImages-1356817526-e1663095877427.jpg"
            alt="..."
            fluid
          />
          <div>
            <Typography.MediumText
              text="Basic Electronics"
              xStyle="font-semibold text-defaultBlue"
            />
            <div className="mt-[15px] flex gap-[10px]">
              <p className="bg-defaultBlue_60 text-defaultWhite px-[6px] py-[1px] rounded-[20px] text-[12px]">
                Starts: Week 3 May
              </p>
              <p className="bg-tertiary-pink text-defaultWhite px-[6px] py-[1px] rounded-[20px] text-[12px]">
                16 weeks
              </p>
            </div>
            <div className="w-full mt-[15px]">
              <div className="bg-gray-200 h-[3px] rounded-[15px]">
                <div
                  className="bg-defaultYellow h-[3px] rounded-[15px]"
                  style={{ width: `20%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* right flex*/}
        <div className="flex gap-[60px] justify-center items-center">
          <div className="text-center">
            <Typography.HeadingThree
              text="8"
              xStyle="font-semibold text-defaultGreen"
            />
            <Typography.MediumText text="Units" xStyle="text-defaultText" />
          </div>
          <div className="text-center">
            <Typography.HeadingThree
              text="6"
              xStyle="font-semibold text-defaultYellow"
            />
            <Typography.MediumText text="Tasks" xStyle="text-defaultText" />
          </div>
          <div className="text-center">
            <Typography.HeadingThree
              text="100"
              xStyle="font-semibold text-defaultBlue"
            />
            <Typography.MediumText
              text="Max Points"
              xStyle="text-defaultText"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentCourseAccordion;
