import { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "../../components/typography/Typography";
import profile_icon from "../../images/profile_icon.png";
import notify_icon from "../../images/notify_icon.png";
import sch_icon from "../../images/sch_icon.png";
import PersonalDetails from "./PersonalDetails";
import ProfilePicture from "./ProfilePicture";
import NameAndRole from "./NameAndRole";
import ContactDetails from "./ContactDetails";
import WorkDetails from "./WorkDetails";
import { NormalButtons } from "../../components/buttons/NormalButtons";
import Notifications from "./notifications/Notifications";
import SchoolNotify from "./school_settings/SchoolNotify";

const Settings = () => {
  const [tabActive, setTabActive] = useState(0);

  const tabNames = [
    {
      name: "Profile",
      icon: <Image className="" src={profile_icon} alt="..." fluid />,
    },
    {
      name: "Notification",
      icon: <Image className="" src={notify_icon} alt="..." fluid />,
    },
    {
      name: "School Settings",
      icon: <Image className="" src={sch_icon} alt="..." fluid />,
    },
  ];

  //Tab active
  const showActive = (i: any) => {
    // console.log(tabActive)
    if (i === 0 && i === tabActive) {
      return "w-auto h-[60px] flex items-center gap-[17px] pr-[40px] pl-[10px] bg-gradient-to-r from-gradient-lightBlueStart to-gradient-lightBlueEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 1 && i === tabActive) {
      return "w-auto h-[60px] flex items-center gap-[17px] pr-[40px] pl-[10px] bg-gradient-to-r from-gradient-yellowEnd to-gradient-yellowStart text-defaultWhite shadow-sm rounded-[20px]";
    } else if (i === 2 && i === tabActive) {
      return "w-auto h-[60px] flex items-center gap-[17px] pr-[40px] pl-[10px] bg-gradient-to-r from-gradient-lightGreenStart to-gradient-lightGreenEnd text-defaultWhite shadow-sm rounded-[20px]";
    } else {
      return "w-auto h-[60px] flex items-center gap-[17px] pr-[40px] pl-[10px] bg-defaultWhite shadow-sm rounded-[20px]";
    }
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar settingsActive="active" settingsIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Typography.HeadingThree text="Settings" />

          <Row className="mt-[10px]">
            {/* left column items */}
            <Col md={9} className="mt-[10px]">
              <Tabs>
                <TabList className="flex gap-[12px] mb-[20px]">
                  {tabNames.map((item, index) => (
                    <Tab key={index} className="bg-transparent">
                      <button
                        onClick={setTabActive.bind(this, index)}
                        key={index}
                        className={showActive(index)}
                      >
                        {item.icon}
                        <Typography.MediumText text={item.name} />
                      </button>
                    </Tab>
                  ))}
                </TabList>

                <TabPanel>
                  <PersonalDetails />
                  <WorkDetails />
                  <ContactDetails />
                </TabPanel>
                <TabPanel>
                  <Notifications />
                </TabPanel>
                <TabPanel>
                  <SchoolNotify />
                </TabPanel>
              </Tabs>
            </Col>

            {/* right column items */}
            <Col className="mt-[10px]" md={3}>
              <div className="border-[1px] border-defaultBlue_40 rounded-[20px] px-[30px] py-[20px]">
                {/* profile pic section */}
                <ProfilePicture />

                {/* name section */}
                <NameAndRole />
              </div>

              {/* change password button */}
              <NormalButtons.Outlined
                btnName="Change Password"
                xStyle="mt-[24px] bg-defaultWhite w-full text-sm"
                onClick={() => alert("clicked")}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Settings;
