import { ProSidebarProvider } from "react-pro-sidebar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "../src/css/App.css";
import "./css/GlobalStyles.css";
import Dashboard from "./pages/dashboard/Dashboard";
import Courses from "./pages/courses/Courses";
import CourseGroup from "./pages/year_group/CourseGroup";
import Classroom from "./pages/year_group/Classroom/Classroom";
import MyClass from "./pages/my_class/MyClass";
import AddClass from "./pages/my_class/addClass/AddClass";
import Settings from "./pages/settings_page/Settings";
import StudentDetails from "./pages/student_details/StudentDetails";
import Reporting from "./pages/reporting/Reporting";
import PreviewReport from "./pages/reporting/generate_report/PreviewReport";
import Preferences from "./pages/reporting/generate_report/report_preferences/Preferences";
import AddQuiz from "./pages/addQuiz/AddQuiz";
import PreviewQuiz from "./pages/addQuiz/PreviewQuiz";
import { GeneralContextProvider } from "./context/generalContext";
import ProtectedRoutes from "./router/router";
import OnBoard from "./pages/OnBoard/OnBoard";
import Account from "./pages/Account/Account";
import Calender from "./pages/Calender/Calender";
import { getCookie } from "./helpers/utils";

function App() {
  const onBoardingData = getCookie("onBoardingData");
  console.log("onBoardingData : ", onBoardingData);

  return (
    <ProSidebarProvider>
      <GeneralContextProvider>
        <Router>
          <Routes>
            {onBoardingData ? (
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            ) : (
              <>
                <Route path="/*" element={<h4>Page not found!!</h4>} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoutes>
                      <OnBoard />
                    </ProtectedRoutes>
                  }
                />
              </>
            )}

            {/* Protected routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoutes>
                  <Dashboard />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/calendar"
              element={
                <ProtectedRoutes>
                  <Calender />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoutes>
                  <Account />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/courses"
              element={
                <ProtectedRoutes>
                  <Courses />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/year_group"
              element={
                <ProtectedRoutes>
                  <CourseGroup />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/year_overview"
              element={
                <ProtectedRoutes>
                  <Classroom />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/my_class"
              element={
                <ProtectedRoutes>
                  <MyClass />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/addClass"
              element={
                <ProtectedRoutes>
                  <AddClass />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoutes>
                  <Settings />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/student_details"
              element={
                <ProtectedRoutes>
                  <StudentDetails />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/reporting"
              element={
                <ProtectedRoutes>
                  <Reporting />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/preview_report"
              element={
                <ProtectedRoutes>
                  <PreviewReport />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/report_preference"
              element={
                <ProtectedRoutes>
                  <Preferences />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/add_quiz"
              element={
                <ProtectedRoutes>
                  <AddQuiz />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/preview_quiz"
              element={
                <ProtectedRoutes>
                  <PreviewQuiz />
                </ProtectedRoutes>
              }
            />
          </Routes>
        </Router>
      </GeneralContextProvider>
    </ProSidebarProvider>
  );
}

export default App;
