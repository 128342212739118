import CurrentCourseAccordion from "./CurrentCourseAccordion";

const Current = () => {
  return (
    <>
      <CurrentCourseAccordion />
      <CurrentCourseAccordion />
    </>
  );
};

export default Current;
