import { Typography } from "../../components/typography/Typography";

const NameAndRole = () => {
  return (
    <div className="text-center mt-[10px]">
      <Typography.MediumText
        text="Mawuli Togbor"
        xStyle="font-semibold text-defaultText"
      />
      <Typography.SmallText text="email@gmail.com" xStyle="text-defaultBlue" />
      <Typography.SmallText text="MBX775398xcyyu87" xStyle="text-defaultText" />

      <div className="mt-[10px] text-defaultText flex items-center justify-center gap-[30px]">
        <div>
          <Typography.HeadingThree text="2001" />
          <Typography.SmallText text="Students" />
        </div>
        <div className="h-[50px] border border-defaultBlue_40"></div>
        <div>
          <Typography.HeadingThree text="34" />
          <Typography.SmallText text="Classes" />
        </div>
      </div>
    </div>
  );
};

export default NameAndRole;
