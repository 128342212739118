import { Col, Row } from "react-bootstrap";
import { Typography } from "../../../components/typography/Typography";
import { MingoInput } from "../../../components/inputs/MingoInput";
import { PencilSquareIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const GenerateReport = () => {
  const navigate = useNavigate();
  return (
    <Row>
      {/* left side */}
      <Col md={9}>
        <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] pt-[20px] pb-[40px]">
          <Typography.MediumText text="Generate Report" />

          <div className="mt-[5px]">
            <Row>
              <Col md={3} className="mt-[10px] flex items-center">
                <Typography.MediumText text="Term" />
              </Col>
              <Col md={9} className="mt-[10px]">
                <MingoInput.OptionsSelect options={<option>Term 2</option>} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3} className="mt-[10px] flex items-center">
                <Typography.MediumText text="Generate for grade / year" />
              </Col>
              <Col md={9} className="mt-[10px] flex gap-5 items-center">
                <MingoInput.CheckBox label="Grade 1" />
                <MingoInput.CheckBox label="Grade 2" />
                <MingoInput.CheckBox label="Grade 3" />
              </Col>
            </Row>
            <Row>
              <Col md={3} className="mt-[10px] flex items-center">
                <Typography.MediumText text="Report Template" />
              </Col>
              <Col md={9} className="mt-[10px] flex">
                <MingoInput.OptionsSelect options={<option>Term 2</option>} />
                <button
                  onClick={() => navigate("/report_preference")}
                  className="bg-defaultBlue h-[40px] px-3 text-white rounded-[5px]"
                >
                  <PencilSquareIcon className="w-5 h-5" />
                </button>
              </Col>
            </Row>
            <Row>
              <Col md={3} className="mt-[10px] flex items-center">
                <Typography.MediumText text="Program" />
              </Col>
              <Col md={9} className="mt-[10px]">
                <MingoInput.OptionsSelect options={<option>Term 2</option>} />
              </Col>
            </Row>
          </div>

          <hr className="my-[30px] border-[1.5px] border-defaultBlue_40" />

          <Typography.MediumText text="Export Preference" />

          <div className="mt-[5px]">
            <Row>
              <Col md={3} className="mt-[10px] flex items-center">
                <Typography.MediumText text="Report Title" />
              </Col>
              <Col md={9} className="mt-[10px]">
                <MingoInput.OptionsSelect options={<option>Term 2</option>} />
              </Col>
            </Row>
            <Row>
              <Col md={3} className="mt-[10px]">
                <Typography.MediumText text="Preparation Date" />
              </Col>
              <Col md={9} className="mt-[10px]">
                <MingoInput.Cornered type="date" />
                <div className="mt-3">
                  <MingoInput.CheckBox label="Send report notification email to parents" />
                </div>
              </Col>
            </Row>
          </div>
          <hr className="my-[30px] border-[1.5px] border-defaultBlue_40" />
          <div className="flex justify-between items-center">
            <button
              className="font-semibold text-defaultBlue"
              onClick={() => navigate("/preview_report")}
            >
              Preview Report
            </button>
            <div className="flex gap-3">
              <button className="border-[2px] border-defaultBlue text-defaultBlue px-3 py-1 rounded-[20px] h-[40px] w-auto">
                Save as Draft
              </button>
              <button className="bg-defaultBlue text-white h-[40px] w-auto py-1 px-3 rounded-[20px]">
                Generate Report
              </button>
            </div>
          </div>
        </div>
      </Col>

      {/* right side */}
      <Col md={3}>
        <button className="rounded-[5px] text-white bg-defaultBlue h-[40px] w-auto px-3 flex justify-center items-center gap-[10px]">
          <UserPlusIcon className="w-5 h-5" />
          Create Report Template
        </button>

        <button className="mt-3 rounded-[5px] text-defaultBlue bg-white h-[40px] border-[1.5px] border-defaultBlue_40 w-auto px-3">
          Send report email
        </button>
      </Col>
    </Row>
  );
};

export default GenerateReport;
